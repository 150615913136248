import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { PublicLayout } from './PublicLayout';

export const PublicLayoutRoute = () => {
  return (
    <PublicLayout>
      <Suspense fallback={<div />}>
        <Outlet />
      </Suspense>
    </PublicLayout>
  );
};
