import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { usePasswordResetRequest } from '../../api/hooks';
import { RequestPasswordResetCommand } from '../../api/types';
import { Button } from '../../design/button/Button';
import { FormikField } from '../../design/field/Field';
import { AuthenticationLayout } from '../AuthenticationLayout';

export const PasswordResetRequestPage = () => {
  const { requestPasswordReset, isSuccess } = usePasswordResetRequest();
  return (
    <AuthenticationLayout title="Password reset">
      {!isSuccess ? (
        <PasswordResetRequestForm onPasswordResetRequest={requestPasswordReset} />
      ) : (
        <PasswordResetRequestSuccess />
      )}
    </AuthenticationLayout>
  );
};

const PasswordResetRequestForm = ({
  onPasswordResetRequest,
}: {
  onPasswordResetRequest: (req: RequestPasswordResetCommand) => Promise<void>;
}) => {
  return (
    <>
      <p className="pb-4">
        Enter the email address associated with your account and we'll send you a link to reset your password.
      </p>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={object().shape({
          email: string().trim().email('Check that your email is correct').required('Enter your email'),
        })}
        onSubmit={async (values) => {
          await onPasswordResetRequest(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormikField label="Email" name="email" />
            <Button type="submit" primary block disabled={isSubmitting} className="mt-4">
              Request password reset
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

const PasswordResetRequestSuccess = () => {
  return (
    <div>
      <p>
        Your request has been received. If we have an account matching your email address, you will receive an email
        with a link to reset your password.
      </p>
      <Button primary block className="mt-4" type="link" href="/login">
        Login
      </Button>
    </div>
  );
};
