import http, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  SeniorityBenchmark,
  SeniorityBenchmarksQuery,
  DefaultFilters,
  EmploymentsImport,
  EmploymentsImportStatus,
  HeadcountRange,
  Location,
  LoginCommand,
  Position,
  RegisterCommand,
  SaveProspectCommand,
  Seniority,
  User,
  Industry,
  SaveCompanyCommand,
  Company,
  FundingStage,
  RequestPasswordResetCommand,
  ResetPasswordCommand,
  CreateKomboIntegrationCommand,
  HRISIntegration,
  KomboConnectionLink,
  UserRole,
  FilterEmploymentsImportsQuery,
  EmploymentsImportListItem,
  EmploymentsImportAssignee,
  ImportRecord,
  LabelImportRecordCommand,
  PatchEmploymentsImportAssigneeCommand,
  PositionSenioritiesQuery,
  LocationsQuery,
  FilterImportRecordsQuery,
  ImportRecordToLabelQuery,
  ImportRecordWithLabels,
  EmploymentsImportStatusUpdateCommand,
  FilterCompanyImportRecordsQuery,
  ConfirmImportRecordCommand,
  RequestRecordVerificationCommand,
  EmploymentsImportType,
  PaginationResult,
  Employment,
  FilterCompanyEmploymentsQuery,
  EditCompanyCommand,
  CompanyStats,
  SuggestEmploymentChangesCommand,
  Role,
  InviteUserCommand,
  Invitation,
  Subscription,
  OnboardingState,
  InvitationQuery,
  AcceptInvitationCommand,
  IntercomSettings,
  ExportBenchmarksQuery,
} from './types';

export const api = http.create({
  baseURL: '/api',
  headers: {
    'x-csrf-token': 'compensation',
  },
});

// User and authentication
export async function register(user: RegisterCommand): Promise<User> {
  const result = await withErrorTransform(api.post('/v1/register', user));
  return transformUser(result.data);
}

export async function acceptInvitation(companyId: string, command: AcceptInvitationCommand): Promise<User> {
  const result = await withErrorTransform(api.post(`/v1/companies/${companyId}/invitees`, command));
  return transformUser(result.data);
}

export async function requestPasswordReset(resetRequest: RequestPasswordResetCommand): Promise<void> {
  await withErrorTransform(api.post('/v1/request-password-reset', resetRequest));
}

export async function resetPassword(resetCommand: ResetPasswordCommand): Promise<void> {
  await withErrorTransform(api.patch('/v1/reset-password', resetCommand));
}

export async function saveCompany(company: SaveCompanyCommand): Promise<Company> {
  const result = await withErrorTransform(api.post('/v1/companies', company));
  return transformCompany(result.data);
}

export async function editCompany({ id, ...editCommand }: EditCompanyCommand): Promise<Company> {
  const result = await withErrorTransform(api.patch(`/v1/companies/${id}`, editCommand));
  return transformCompany(result.data);
}

export async function removeUserFromCompany(companyId: string, userId: string): Promise<User> {
  const result = await withErrorTransform(api.delete(`/v1/companies/${companyId}/users/${userId}`));
  return transformUser(result.data);
}

export async function suggestEmploymentChanges(
  companyId: string,
  { employmentId, ...suggestCommand }: SuggestEmploymentChangesCommand
): Promise<Employment> {
  const result = await withErrorTransform(
    api.post(`/v1/companies/${companyId}/employments/${employmentId}/suggestions`, suggestCommand)
  );
  return result.data;
}

export async function confirmImportRecord(
  companyId: string,
  { importRecordId, importRecordLabelsId, ...command }: ConfirmImportRecordCommand
): Promise<ImportRecordWithLabels> {
  const result = await withErrorTransform(
    api.post(
      `/v1/companies/${companyId}/import-records/${importRecordId}/labels/${importRecordLabelsId}/verifications`,
      command
    )
  );
  return result.data;
}

export async function saveProspect(prospect: SaveProspectCommand): Promise<void> {
  await withErrorTransform(api.post('/v1/prospects', prospect));
}

export async function fetchLatestEmploymentsImport(companyId: string): Promise<EmploymentsImport | null> {
  const result = await with404ToNullErrorTransform(api.get(`/v1/companies/${companyId}/employments-imports/latest`));
  return transformEmploymentsImport(result?.data);
}

export async function fetchUserCompanies(): Promise<Company[]> {
  const result = await withErrorTransform(api.get(`/v1/me/companies`));
  return result.data.map(transformCompany);
}

export async function fetchRoles(): Promise<Role[]> {
  const result = await withErrorTransform(api.get(`/v1/roles`));
  return result.data.map(transformRole);
}

export async function fetchOnboardingState(companyId?: string): Promise<OnboardingState> {
  const params = companyId ? { companyId } : undefined;
  const result = await withErrorTransform(api.get(`/v1/onboarding`, { params }));
  return result.data;
}

export async function fetchCompanyUsers(companyId: string): Promise<User[]> {
  const result = await withErrorTransform(api.get(`/v1/companies/${companyId}/users`));
  return result.data.map(transformUser);
}

export async function fetchCompanyStats(companyId: string): Promise<CompanyStats> {
  const result = await withErrorTransform(api.get(`/v1/companies/${companyId}/stats`));
  return result.data;
}

export async function fetchEmploymentsImportAssignees(): Promise<EmploymentsImportAssignee[]> {
  const result = await withErrorTransform(api.get(`/v1/backoffice/employments-imports/assignees`));
  return result.data || [];
}

export async function fetchImportRecords({
  employmentsImportId,
  ...filter
}: FilterImportRecordsQuery): Promise<PaginationResult<ImportRecordWithLabels>> {
  const result = await withErrorTransform(
    api.get(`/v1/backoffice/employments-imports/${employmentsImportId}/records`, {
      params: filter,
      paramsSerializer: {
        indexes: null,
      },
    })
  );
  return result.data;
}

export async function fetchCompanyImportRecords({
  companyId,
  ...filter
}: FilterCompanyImportRecordsQuery): Promise<PaginationResult<ImportRecordWithLabels>> {
  const result = await withErrorTransform(
    api.get(`/v1/companies/${companyId}/records`, {
      params: filter,
      paramsSerializer: {
        indexes: null,
      },
    })
  );
  return result.data;
}

export async function fetchImportRecordToLabel({
  employmentsImportId,
  importRecordId,
}: ImportRecordToLabelQuery): Promise<ImportRecordWithLabels | undefined> {
  const result = await withErrorTransform(
    api.get(`/v1/backoffice/employments-imports/${employmentsImportId}/record-to-label`, { params: { importRecordId } })
  );
  return result.data;
}

export async function labelImportRecord({
  importRecordId,
  ...labelCommand
}: LabelImportRecordCommand): Promise<ImportRecord> {
  const result = await withErrorTransform(
    api.post(`/v1/backoffice/import-records/${importRecordId}/label`, labelCommand)
  );

  return result.data;
}

export async function skipImportRecord(importRecordId: string): Promise<ImportRecord> {
  const result = await withErrorTransform(api.post(`/v1/backoffice/import-records/${importRecordId}/skip`));

  return result.data;
}

export async function terminateImportRecord(importRecordId: string): Promise<ImportRecord> {
  const result = await withErrorTransform(api.post(`/v1/backoffice/import-records/${importRecordId}/terminate`));

  return result.data;
}

export async function requestRecordVerification({
  importRecordId,
  ...confirmationCommand
}: RequestRecordVerificationCommand): Promise<ImportRecordWithLabels> {
  const result = await withErrorTransform(
    api.post(`/v1/backoffice/import-records/${importRecordId}/labels/verifications`, confirmationCommand)
  );

  return result.data;
}

export async function patchEmploymentsImportAssignee({
  employmentsImportId,
  assignedUserId,
}: PatchEmploymentsImportAssigneeCommand): Promise<EmploymentsImport> {
  const result = await withErrorTransform(
    api.patch(`/v1/backoffice/employments-imports/${employmentsImportId}`, { assignedUserId })
  );

  return result.data;
}

export async function updateEmploymentsImportStatus({
  employmentsImportId,
  status,
}: EmploymentsImportStatusUpdateCommand): Promise<EmploymentsImport> {
  const result = await withErrorTransform(
    api.put(`/v1/backoffice/employments-imports/${employmentsImportId}/status`, { status })
  );

  return result.data;
}

export async function fetchEmploymentsImports(
  query: FilterEmploymentsImportsQuery
): Promise<PaginationResult<EmploymentsImportListItem>> {
  const {
    data: { items, totalCount },
  } = await withErrorTransform(
    api.get('/v1/backoffice/employments-imports', {
      params: query,
      paramsSerializer: {
        indexes: null,
      },
    })
  );
  return { items: items?.map(transformEmploymentsImportListItem) || [], totalCount };
}

export async function fetchCompanyEmployments({
  companyId,
  ...filter
}: FilterCompanyEmploymentsQuery): Promise<PaginationResult<Employment>> {
  const result = await withErrorTransform(
    api.get(`/v1/companies/${companyId}/employments`, {
      params: filter,
      paramsSerializer: {
        indexes: null,
      },
    })
  );
  return result.data;
}

export async function fetchEmploymentsImport(employmentsImportId: string): Promise<EmploymentsImport | null> {
  const result = await with404ToNullErrorTransform(
    api.get(`/v1/backoffice/employments-imports/${employmentsImportId}`)
  );
  return transformEmploymentsImport(result?.data);
}

export async function uploadEmploymentsImportCSV(
  companyId: string,
  importFile: File,
  axiosRequestConfig: AxiosRequestConfig
): Promise<EmploymentsImport | null> {
  const formData = new FormData();
  formData.append('file', importFile);
  try {
    const result = await api.post(`/v1/companies/${companyId}/employments-imports/csv`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...axiosRequestConfig,
    });
    return transformEmploymentsImport(result.data);
  } catch (e) {
    if (http.isCancel(e)) {
      return null;
    } else if (e instanceof Error && isAxiosError(e)) {
      throw new ApiError(e);
    }
    throw e;
  }
}

export async function inviteUser(companyId: string, command: InviteUserCommand): Promise<Invitation> {
  const result = await withErrorTransform(api.post(`/v1/companies/${companyId}/invitations`, command));
  return result.data;
}

export async function fetchInvitation(command: InvitationQuery): Promise<Invitation | null> {
  const result = await withErrorTransform(api.post(`/v1/invitation-searches`, command));
  return result.data;
}

export async function login(user: LoginCommand): Promise<User> {
  const result = await withErrorTransform(api.post('/v1/login', user));
  return transformUser(result.data);
}

export async function logout(): Promise<void> {
  await withErrorTransform(api.post('/v1/logout'));
}

export async function fetchSelf(): Promise<User> {
  const result = await withErrorTransform(api.get('/v1/me'));
  return transformUser(result.data);
}

export async function fetchSelfRoles(): Promise<UserRole[]> {
  const result = await withErrorTransform(api.get('/v1/me/roles'));
  return result.data && result.data.length ? result.data.map(transformUserRole) : [];
}

function transformUserRole(userRole: {
  id: string;
  name: string;
  userId: string;
  companyId?: string;
  creationTime: string;
}): UserRole {
  return {
    ...userRole,
    creationTime: new Date(userRole.creationTime),
  };
}

function transformRole({ creationTime, ...role }: { name: string; description: string; creationTime: string }): Role {
  return {
    ...role,
    creationTime: new Date(creationTime),
  };
}

function transformUser(user: {
  id: string;
  email: string;
  name: string;
  companyIds: string[];
  creationTime: string;
  modificationTime: string;
}): User {
  return {
    ...user,
    creationTime: new Date(user.creationTime),
    modificationTime: new Date(user.modificationTime),
  };
}

function transformCompany(company: {
  id: string;
  headquartersLocationId: string;
  headcountRangeId: string;
  fundingStage: FundingStage;
  industryId: string | null;
  name: string;
  targetPercentile: number;
  creationTime: string;
  modificationTime: string;
}): Company {
  return {
    ...company,
    creationTime: new Date(company.creationTime),
    modificationTime: new Date(company.modificationTime),
  };
}

function transformEmploymentsImport(employmentsImport?: {
  id: string;
  status: string;
  source: string;
  type: string;
  createdBy: string | null;
  companyId: string;
  creationTime: Date;
  modificationTime: Date;
}): EmploymentsImport | null {
  if (!employmentsImport) {
    return null;
  }
  const statusEnum = employmentsImport.status as EmploymentsImportStatus;
  return {
    ...employmentsImport,
    type: EmploymentsImportType[employmentsImport.type as keyof typeof EmploymentsImportType],
    status: statusEnum,
    creationTime: new Date(employmentsImport.creationTime),
    modificationTime: new Date(employmentsImport.modificationTime),
  };
}

function transformEmploymentsImportListItem(
  employmentsImport?: EmploymentsImportListItem
): EmploymentsImportListItem | null {
  if (!employmentsImport) {
    return null;
  }
  return {
    ...employmentsImport,
    creationTime: new Date(employmentsImport.creationTime),
  };
}

// Benchmarking filters
export async function fetchLocations(locationsQuery: LocationsQuery): Promise<Location[]> {
  const response = await withErrorTransform(api.get('/v1/locations', { params: locationsQuery }));
  return response.data;
}

export async function fetchPositions(): Promise<Position[]> {
  const response = await withErrorTransform(api.get('/v1/positions'));
  return response.data;
}

export async function fetchPositionSeniorities(
  positionId: string,
  params?: PositionSenioritiesQuery
): Promise<Seniority[]> {
  const response = await withErrorTransform(api.get(`/v1/positions/${positionId}/seniorities`, { params }));
  return response.data;
}

export async function fetchHeadcountRanges(): Promise<HeadcountRange[]> {
  const response = await withErrorTransform(api.get('/v1/headcount-ranges'));
  const ranges: HeadcountRange[] = response.data;
  ranges.sort((a, b) => a.min - b.min);
  return ranges;
}

export async function fetchIndustries(): Promise<Industry[]> {
  const response = await withErrorTransform(api.get('/v1/industries'));
  return response.data;
}

// Benchmarking
export async function fetchSeniorityBenchmarks(query: SeniorityBenchmarksQuery): Promise<SeniorityBenchmark[]> {
  const response = await withErrorTransform(api.get('/v1/benchmark', { params: query }));
  return response.data.map((item: SeniorityBenchmark) => ({ ...item, lastUpdated: new Date(item.lastUpdated) }));
}

export async function fetchPublicSeniorityBenchmarks(): Promise<SeniorityBenchmark[]> {
  const response = await withErrorTransform(api.get('/v1/public-benchmark'));
  return response.data.map((item: SeniorityBenchmark) => ({ ...item, lastUpdated: new Date(item.lastUpdated) }));
}

export async function fetchDefaultFilters(): Promise<DefaultFilters> {
  const response = await withErrorTransform(api.get('/v1/benchmark/default-filters'));
  return response.data;
}

// Export
export async function fetchExportBenchmarks(companyId: string, query: ExportBenchmarksQuery): Promise<AxiosResponse> {
  return withErrorTransform(api.get(`/v1/companies/${companyId}/benchmarks/export`, { params: query, responseType: 'blob' }));
}

// HRIS integration
export async function createKomboConnectionLink(companyId: string): Promise<KomboConnectionLink> {
  const response = await withErrorTransform(api.post(`/v1/companies/${companyId}/integrations/kombo`));
  return response.data;
}

export async function createKomboIntegration(
  companyId: string,
  command: CreateKomboIntegrationCommand
): Promise<HRISIntegration> {
  const response = await withErrorTransform(api.post(`/v1/companies/${companyId}/integrations`, command));
  return transformHRISIntegration(response.data);
}

function transformHRISIntegration(response: {
  id: string;
  hris: string;
  type: string;
  companyId: string;
  createdBy: string;
  creationTime: string;
}): HRISIntegration {
  return {
    id: response.id,
    hris: response.hris,
    type: response.type,
    companyId: response.companyId,
    createdBy: response.createdBy,
    creationTime: new Date(response.creationTime),
  };
}

// subscriptions
export async function fetchSubscription(companyId: string): Promise<Subscription | null> {
  const response = await with404ToNullErrorTransform(api.get(`/v1/companies/${companyId}/subscription`));
  return response?.data ?? null;
}

// intercom
export async function fetchIntercomSettings(): Promise<IntercomSettings | null> {
  const response = await withErrorTransform(api.get('/v1/intercom/settings'));
  return response?.data ?? null;
}

// Error handling

async function with404ToNullErrorTransform<T>(promise: Promise<T>): Promise<T | null> {
  try {
    return await promise;
  } catch (error) {
    if (error instanceof Error && isAxiosError(error)) {
      if (error.response?.status === 404) {
        return null;
      }
      throw new ApiError(error);
    }
    throw error;
  }
}

async function withErrorTransform<T>(promise: Promise<T>): Promise<T> {
  try {
    return await promise;
  } catch (error) {
    if (error instanceof Error && isAxiosError(error)) {
      throw new ApiError(error);
    }
    throw error;
  }
}

export class ApiError extends Error {
  public readonly status: number;
  public readonly errors: ApiErrorValue[];

  constructor(error: AxiosError<any>) {
    if (error.config && error.config.method && error.response) {
      super(
        `Api ${error.config.method!.toUpperCase()} ${error.config.url} failed with ${error.response?.status}: ${
          error.response?.statusText
        }`
      );
    } else {
      super(`Unknown Api error: ${error.config?.method}: ${error.config?.url}`);
    }
    this.status = error.response?.status || 0;
    this.errors = error.response?.data?.errors;
  }
}

export interface ApiErrorValue {
  message: string;
}

function isAxiosError(error: Error): error is AxiosError {
  return !!(error as AxiosError).isAxiosError;
}
