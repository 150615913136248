import React, { ComponentType, ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames';

import { Button, ButtonSize } from '../../design/button/Button';
import cornerOrb from './cornerOrb.webp';
import { Container } from '../../design/container/Container';
import { usePublicBenchmarks } from '../../api/hooks';
import {
  LightYearLogo,
  PrintifyLogo,
  ReadyPlayerMeLogo,
  TestlioLogo,
  TuumLogo,
} from '../../design/partnerLogo/PartnerLogos';
import { Divider } from '../../design/divider/Divider';
import { Card } from '../../design/card/Card';
import { SeniorityBenchmarks } from '../../app/marketData/benchmark/SeniorityBenchmarks';
import { formatAmount, getSeniorityAbbreviation } from '../../app/util/formatUtils';
import { SeniorityBenchmark } from '../../api/types';
import { Loader, LoaderSize } from '../../design/loader/Loader';

import productDemo from './productDemo.svg';
import upwardsGraphInCircle from './upwardsGraphInCircle.svg';
import horizontalGraphInCircle from './horizontalGraphInCircle.svg';
import burgerGraphInCircle from './burgerGraphInCircle.svg';
import ctaBackground from './ctaBackground.webp';
import { Header } from '../../design/header/Header';

export const Homepage: React.FunctionComponent<{}> = () => {
  const { benchmarks, isError } = usePublicBenchmarks();

  return (
    <>
      <section
        className="bg-brandGreen-light bg-no-repeat bg-right-bottom py-32"
        style={{
          backgroundImage: `url(${cornerOrb})`,
          backgroundSize: '60%',
        }}
      >
        <Container>
          <div className="flex flex-col lg:flex-row lg:items-center lg:py-16">
            <div className="lg:w-1/2">
              <h1 className="text-3xl sm:text-6xl font-semibold mb-8">
                Real-time total <br />
                compensation data
              </h1>
              <h3 className="text-xl sm:text-2xl">
                Make smarter compensation decisions <br className="hidden lg:block" />
                based on reliable market insights
              </h3>
              <div className="flex flex-col gap-4 md:flex-row mt-6">
                <Button className="justify-center" type="link" href="/onboarding" size={ButtonSize.LARGE} primary>
                  Company sign up
                </Button>
                {process.env.REACT_APP_DEMO_CALENDLY_LINK && (
                  <Button
                    className="justify-center"
                    type="link"
                    size={ButtonSize.LARGE}
                    href={process.env.REACT_APP_DEMO_CALENDLY_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Schedule a demo
                  </Button>
                )}
              </div>
            </div>
            {!isError ? (
              <Card className="bg-white border-none mt-16 lg:mt-0 lg:w-1/2 shadow-md">
                <AnimatePresence>
                  {benchmarks ? (
                    <PublicBenchmarkDisplay
                      benchmarks={benchmarks}
                      targetPercentile={{ percentile: PUBLIC_BENCHMARK_TARGET_PERCENTILE }}
                    />
                  ) : (
                    <Loader className="my-28" size={LoaderSize.LARGE} />
                  )}
                </AnimatePresence>
              </Card>
            ) : (
              ''
            )}
          </div>
        </Container>
      </section>
      <Container>
        <TrustElement />
        <ValueExplainer />
      </Container>
      <FinalUpSell />
    </>
  );
};

const PUBLIC_BENCHMARK_POSITION_NAME = 'Software engineer';
const PUBLIC_BENCHMARK_LOCATION_NAME = 'Estonia';
const PUBLIC_BENCHMARK_TARGET_PERCENTILE = 50;
const PublicBenchmarkDisplay: ComponentType<{
  benchmarks: SeniorityBenchmark[];
  targetPercentile: { percentile: number };
}> = ({ benchmarks, targetPercentile }) => {
  const selectedBenchmark = benchmarks[0];
  const salary = benchmarks[0].percentiles?.find(
    (percentile) => percentile.value === targetPercentile.percentile
  )?.score;
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <p>Last updated: {selectedBenchmark.lastUpdated.toDateString()}</p>
      <Header>
        {PUBLIC_BENCHMARK_POSITION_NAME} – {getSeniorityAbbreviation(selectedBenchmark.seniority)} –{' '}
        {PUBLIC_BENCHMARK_LOCATION_NAME}
      </Header>
      {salary && <p className="text-6xl font-semibold mb-6">{formatAmount(salary)}€</p>}
      <SeniorityBenchmarks
        benchmarks={benchmarks}
        selectedSeniorityId={selectedBenchmark.seniority.id}
        targetPercentile={targetPercentile}
      />
    </motion.div>
  );
};

const TrustElement: ComponentType<{}> = () => (
  <section className="flex py-32 justify-center">
    <div className="w-full">
      <h2 className="text-2xl sm:text-4xl font-semibold text-center mb-16">
        Trusted by the most competitive employers in the Baltics
      </h2>
      <Divider />
      <div className="flex gap-10 justify-around flex-col sm:flex-row items-center py-12">
        <div>
          <LightYearLogo />
        </div>
        <div>
          <ReadyPlayerMeLogo />
        </div>
        <div>
          <PrintifyLogo />
        </div>
        <div>
          <TuumLogo />
        </div>
        <div>
          <TestlioLogo />
        </div>
      </div>
      <Divider />
    </div>
  </section>
);

const ValueExplainer: ComponentType<{}> = () => (
  <section className="pb-32">
    <h2 className="text-3xl sm:text-5xl font-semibold text-center md:text-left">The numbers you need</h2>
    <div className="flex flex-col md:flex-row md:justify-between">
      <div className="md:w-1/2 md:mr-8 md:mt-16 lg:mt-0 self-center">
        <MediaElement
          illustration={<img src={upwardsGraphInCircle} alt="" />}
          title="Real-time"
          description="We integrate with 20+ HRIS providers which means we always show real numbers"
          className="mb-16"
        />
        <MediaElement
          illustration={<img src={horizontalGraphInCircle} alt="" />}
          title="Standardised"
          description="Our team makes sure that compensation levels are standardised across organisations"
          className="mb-16"
        />
        <MediaElement
          illustration={<img src={burgerGraphInCircle} alt="" />}
          title="Actually useful"
          className="mb-16"
          description="You can build your own competitive set through company-level filters such as industry, funding stage and employee count"
        />
      </div>
      <div className="order-first md:order-last md:w-1/2 self-center mt-4 sm:mt-0 lg:-mt-16">
        <img
          src={productDemo}
          className="block w-full"
          alt="An illustration of the filters available inside the product"
        />
      </div>
    </div>
  </section>
);

const MediaElement: ComponentType<{
  illustration: ReactNode;
  title: ReactNode;
  description: ReactNode;
  className?: string;
}> = ({ illustration, title, description, className }) => (
  <div className={classNames('flex', className)}>
    <div className="mr-8 flex-shrink-0 self-center">{illustration}</div>
    <div>
      <h4 className="text-lg font-bold">{title}</h4>
      <p className="mt-1">{description}</p>
    </div>
  </div>
);

const FinalUpSell: ComponentType<{}> = () => (
  <section
    className="bg-brandGreen-light bg-no-repeat bg-cover bg-center py-32"
    style={{
      backgroundImage: `url(${ctaBackground})`,
    }}
  >
    <Container>
      <Card className="bg-white border-none mx-auto w-full md:w-2/3 shadow-sm text-center">
        <h2 className="text-2xl sm:text-4xl font-semibold text-center mb-6">
          Goodbye PDFs!*
          <br /> Align to real time data
        </h2>
        <p>*PDFs are great, but we think you and your employees deserve better</p>

        <Button type="link" href="/onboarding" size={ButtonSize.LARGE} primary className="mt-8">
          Company sign up
        </Button>
      </Card>
    </Container>
  </section>
);
