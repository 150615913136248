import { motion } from 'framer-motion';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEmploymentsImport, useLabeling } from '../../api/hooks';
import { ImportRecord } from '../../api/types';
import { AppLayoutTransition } from '../../app/layout/AppLayoutTransition';
import { formatMoney } from '../../app/util/formatUtils';
import { Button, ButtonSize } from '../../design/button/Button';
import { Detail } from '../../design/detail/Detail';
import { Header, HeaderSize } from '../../design/header/Header';
import { Success } from '../../design/icons/Icons';
import { Loader, LoaderSize } from '../../design/loader/Loader';
import { Modal } from '../../design/modal/Modal';
import { ContentTransition } from '../../design/transition/ContentTransition';
import { DetailsLayout } from '../../app/layout/DetailsLayout';
import { ImportRecordLabels } from './ImportRecordLabels';
import { ImportRecordLocationDetails } from '../../app/importRecord/ImportRecordLocationDetails';

export function ImportRecordLabelingPage() {
  const { employmentsImportId, importRecordId } = useParams();
  const { employmentsImport } = useEmploymentsImport(employmentsImportId);
  const [allRecordsLabeled, setAllRecordsLabeled] = useState(false);

  const { importRecord, isFetching, isLabeling, ...actions } = useLabeling({
    employmentsImportId,
    importRecordId,
    onAllRecordsCompleted: () => setAllRecordsLabeled(true),
  });

  let renderedComponent;
  if (isFetching) {
    renderedComponent = (
      <motion.div key="loader">
        <Loader size={LoaderSize.LARGE} className="xl:mt-32" />
      </motion.div>
    );
  } else if (allRecordsLabeled) {
    renderedComponent = (
      <ContentTransition id="all-records-labeled">
        <AllRecordsLabeled employmentsImportId={employmentsImportId!} importRecordId={importRecordId} />
      </ContentTransition>
    );
  } else {
    renderedComponent = (
      <ContentTransition id={`import-record-${importRecord?.id}`}>
        <div className="flex flex-col gap-12">
          <ImportRecordInfoSection importRecord={importRecord} isLoading={isFetching} />
          {importRecord && (
            <ImportRecordLabels
              importRecord={importRecord}
              isLabeling={isLabeling}
              labelingActions={actions}
              employmentsImportType={employmentsImport?.type}
            />
          )}
        </div>
      </ContentTransition>
    );
  }

  return (
    <AppLayoutTransition id="employments-import-page">
      <Header size={HeaderSize.LARGE}>Label Record</Header>
      {renderedComponent}
    </AppLayoutTransition>
  );
}

function ImportRecordInfoSection({ importRecord, isLoading }: { importRecord?: ImportRecord; isLoading: boolean }) {
  const [rawValueModalOpened, setRawValueModalOpened] = useState(false);

  return (
    <div className="flex flex-col">
      <Header>Import Record</Header>

      {isLoading ? (
        <Loader size={LoaderSize.MEDIUM} />
      ) : (
        <DetailsLayout>
          <Detail title="Job Title" value={importRecord?.jobTitle} />
          <Detail title="Employment Status" value={importRecord?.employmentStatus} />
          <Detail title="Salary" value={importRecord?.salary && formatMoney(importRecord.salary)} />
          <Detail title="Pay Period" value={importRecord?.payPeriod} />
          <ImportRecordLocationDetails importRecord={importRecord} />
          <Detail title="Employment Type" value={importRecord?.employmentType} />
          <Detail title="Imported Id" value={importRecord?.importedId} />
          <Detail title="Seniority" value={importRecord?.seniority} />
          <Detail
            title="Raw Value"
            value={
              <Button
                disabled={!importRecord?.rawImportValue}
                className="mt-1"
                size={ButtonSize.SMALL}
                onClick={() => setRawValueModalOpened(!rawValueModalOpened)}
              >
                View
              </Button>
            }
          />
        </DetailsLayout>
      )}
      <RawImportRecordModal
        rawImportValue={importRecord?.rawImportValue}
        open={rawValueModalOpened}
        setOpen={setRawValueModalOpened}
      />
    </div>
  );
}

function RawImportRecordModal({
  rawImportValue,
  open,
  setOpen,
}: {
  rawImportValue?: object;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="overflow-auto">
        <pre>{JSON.stringify(rawImportValue, undefined, 2)}</pre>
      </div>
    </Modal>
  );
}

function AllRecordsLabeled({
  employmentsImportId,
  importRecordId,
}: {
  employmentsImportId: string;
  importRecordId?: string;
}) {
  const isLabelingSingleRecord = importRecordId;
  const navigate = useNavigate();

  const backRoute = isLabelingSingleRecord
    ? `/backoffice/employments-imports/${employmentsImportId}`
    : '/backoffice/employments-imports';
  const navText = isLabelingSingleRecord ? 'Back to import' : 'Back to imports';

  return (
    <div className="h-full flex flex-col justify-center items-center py-28">
      <Success className="fill-brandGreen-mid text-9xl" />
      <Header>All done!</Header>
      <Button size={ButtonSize.LARGE} onClick={() => navigate(backRoute)}>
        {navText}
      </Button>
    </div>
  );
}
