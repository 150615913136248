import React, { useState } from 'react';
import { HamburgerMenuButton } from '../../app/layout/HamburgerMenuButton';
import { SidebarContainer, SidebarNavigationItem } from '../../app/layout/SidebarContainer';

const backOfficeNavigationItems: SidebarNavigationItem[] = [
  { name: 'Employments Imports', href: '/backoffice/employments-imports', icon: null },
  { name: 'Application', href: '/market', icon: null },
];

export const BackOfficeLayout: React.FunctionComponent<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <SidebarContainer
        navigationItems={backOfficeNavigationItems}
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      />
      <div className="flex flex-1 flex-col md:pl-64">
        <div className="sticky top-0 z-10 bg-white px-4 sm:px-6 pt-1 sm:pt-3 md:hidden">
          <HamburgerMenuButton onClick={() => setSidebarOpen(true)} />
        </div>
        <main className="flex-1">
          <div className="py-6">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
              <div className="py-4">{children}</div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
