import { ReactElement, useRef } from 'react';
import classNames from 'classnames';
import { Expand, Close } from '../icons/Icons';
import { getInputClassNames, InputSize } from '../input/Input';
import Select, { GroupBase, Props, components, SelectInstance } from 'react-select';
import { Button, ButtonSize } from '../button/Button';
import { FieldSize, getFieldLabelClasses } from '../field/Field';

export enum ComboboxSize {
  SMALL = 'SM',
  MEDIUM = 'MD',
  LARGE = 'LG',
}

const expandIconSizeClasses = {
  [ComboboxSize.SMALL]: 'h-4 w-4',
  [ComboboxSize.MEDIUM]: 'h-5 w-5',
  [ComboboxSize.LARGE]: 'h-6 w-6',
};

export interface ComboboxOption {
  id: string | number;
  value: string;
}

export interface GroupedComboboxOptions extends GroupBase<ComboboxOption> {
  label: string;
  value: ComboboxOption;
  options: ComboboxOption[];
}

interface CustomComboboxProps {
  id?: string;
  size?: ComboboxSize;
  className?: string;
  optionsContainerClassName?: string;
  label?: React.ReactNode;
  isGroupHeaderSelectable?: boolean;
  disabled?: boolean;
}

export type ComboboxProps<IsMulti extends boolean> = Props<ComboboxOption, IsMulti, GroupedComboboxOptions> &
  CustomComboboxProps;

export function Combobox<IsMulti extends boolean = false>({
  id,
  size = ComboboxSize.MEDIUM,
  className,
  optionsContainerClassName,
  label,
  isClearable = true,
  isGroupHeaderSelectable = false,
  disabled = false,
  ...props
}: ComboboxProps<IsMulti>): ReactElement {
  const ref = useRef<SelectInstance<ComboboxOption, IsMulti, GroupedComboboxOptions> | null>(null);
  return (
    <div>
      {label && (
        <label htmlFor={id || 'select-input'} className={getFieldLabelClasses(size as unknown as FieldSize)}>
          {label}
        </label>
      )}
      <Select
        isDisabled={disabled}
        ref={ref}
        {...props}
        id={id || 'select-input'}
        inputId={id || 'select-input'}
        className={classNames('mb-4', className)}
        components={{
          DropdownIndicator: (props) => (
            <components.DropdownIndicator {...props}>
              <Expand className={classNames('text-gray-400', expandIconSizeClasses[size])} aria-hidden="true" />
            </components.DropdownIndicator>
          ),
          ClearIndicator: (props) =>
            isClearable === false ? (
              <></>
            ) : (
              <components.ClearIndicator {...props}>
                <Close
                  className={classNames('cursor-pointer text-gray-400', expandIconSizeClasses[size])}
                  aria-hidden="true"
                />
              </components.ClearIndicator>
            ),
          MultiValueRemove: (props) => (
            <components.MultiValueRemove {...props}>
              <Close className={classNames('cursor-pointer text-gray-400')} aria-hidden="true" />
            </components.MultiValueRemove>
          ),
          GroupHeading: (headingProps) => (
            <components.GroupHeading {...headingProps}>
              <div className="flex justify-between items-center select-none bg-slate-200 py-2 px-3">
                <p>{headingProps.data.label}</p>
                {isGroupHeaderSelectable && ref.current?.selectOption && (
                  <Button
                    size={ButtonSize.SMALL}
                    onClick={() => {
                      ref.current?.selectOption(headingProps.data.value);
                    }}
                  >
                    SELECT
                  </Button>
                )}
              </div>
            </components.GroupHeading>
          ),
        }}
        classNames={{
          menuList: () =>
            classNames(
              'absolute z-10 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
              optionsContainerClassName
            ),

          control: ({ isFocused, isDisabled }) =>
            classNames(getInputClassNames({ size: size as unknown as InputSize, block: true, isFocused, isDisabled }), {
              'ring-1': isFocused,
            }),
          option: ({ isFocused, isSelected }) =>
            classNames(
              'relative cursor-default select-none py-2 pl-3 pr-9',
              isFocused ? 'bg-slate-700 text-white' : 'text-gray-900',
              isSelected && 'font-semibold'
            ),
          input: () => '[&>input]:!ring-0',
          indicatorSeparator: () => 'w-[1px] bg-slate-300 mx-1',
          multiValue: () => 'border border-solid border-slate-300 rounded',
          multiValueLabel: () => 'px-2',
          multiValueRemove: () => 'border-l px-1 hover:bg-slate-300',
          valueContainer: () => 'gap-1',
        }}
        placeholder=""
        getOptionLabel={(option) => option.value}
        getOptionValue={(option) => option.id as string}
        isClearable={isClearable}
        unstyled={true}
        menuPlacement="auto"
      />
    </div>
  );
}
