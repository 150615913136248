import React, { useState } from 'react';
import { useHasRoles } from '../../api/hooks';
import { RoleName } from '../../api/types';
import { HamburgerMenuButton } from './HamburgerMenuButton';
import { SidebarContainer, SidebarNavigationItem } from './SidebarContainer';
import { useActiveSubscriptionType } from '../subscription/subscriptions';
import { useActiveCompany } from '../../authentication/ActiveCompany';

export const AppLayout: React.FunctionComponent<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { hasRoles: hasAdminRole } = useHasRoles([RoleName.ALIGN_ADMIN]);

  const navigationItems = hasAdminRole ? appAdminNavigationItems : defaultNavigationItems;

  const activeCompany = useActiveCompany();
  const { isLoading: isLoadingPlans, activeType: activeSubscriptionType } = useActiveSubscriptionType(
    activeCompany?.id
  );

  const navigationItemsWithUpgrade =
    !activeCompany && !isLoadingPlans && !activeSubscriptionType
      ? navigationItems.concat({
          name: 'Upgrade',
          href: '/upgrade',
          icon: null,
        })
      : navigationItems;

  return (
    <div>
      <SidebarContainer
        navigationItems={navigationItemsWithUpgrade}
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      />
      <div className="flex flex-1 flex-col md:pl-64">
        <div className="sticky top-0 z-10 bg-white px-4 sm:px-6 pt-1 sm:pt-3 md:hidden">
          <HamburgerMenuButton onClick={() => setSidebarOpen(true)} />
        </div>
        <main className="flex-1">
          <div className="py-6">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
              <div className="py-4">{children}</div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

const defaultNavigationItems: SidebarNavigationItem[] = [
  { name: 'Market Data', href: '/market', icon: null },
  {
    name: 'Organisation',
    href: '/organisation',
    icon: null,
  },
  {
    name: 'Team',
    href: '/team',
    icon: null,
  },
];

const appAdminNavigationItems: SidebarNavigationItem[] = [
  ...defaultNavigationItems,
  { name: 'Back Office', href: '/backoffice/employments-imports', icon: null },
];
