import React, { forwardRef, MouseEventHandler } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export enum ButtonSize {
  SMALL = 'SM',
  MEDIUM = 'MD',
  LARGE = 'LG',
}

export interface ButtonProps {
  id?: string;
  type?: 'button' | 'submit' | 'link';
  onClick?: MouseEventHandler;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  block?: boolean;
  primary?: boolean;
  size?: ButtonSize;

  href?: string;
  target?: string;
  rel?: string;
}

const animationClasses = `transition ease-in-out duration-200`;

const baseClasses =
  'uppercase items-center rounded-lg border font-semibold tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-sm hover:-translate-y-0.5 hover:shadow-md disabled:translate-y-0 disabled:text-slate-500 disabled:shadow-sm active:translate-y-0 active:ring-0 active:shadow-sm';

const primaryClasses =
  'bg-black hover:bg-slate-800 border-transparent text-white focus:ring-black disabled:bg-slate-300';
const secondaryClasses =
  'bg-transparent border-2 border-black text-black hover:bg-slate-200 focus:ring-black disabled:border-slate-500 disabled:bg-transparent';

const sizeClasses = {
  [ButtonSize.SMALL]: 'px-3 py-1 text-sm',
  [ButtonSize.MEDIUM]: 'px-5 py-3 text-base',
  [ButtonSize.LARGE]: 'px-8 py-4 text-base',
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      children,
      type = 'button',
      onClick,
      disabled = false,
      className: customClassName,
      block = false,
      primary = false,
      size = ButtonSize.MEDIUM,

      href,
      target,
      rel,
    },
    ref
  ) => {
    let classes;
    if (primary) {
      classes = primaryClasses;
    } else {
      classes = secondaryClasses;
    }
    const className = classNames(
      baseClasses,
      animationClasses,
      sizeClasses[size],
      block ? 'flex w-full justify-center' : 'inline-flex',
      classes,
      customClassName
    );
    if (type !== 'link') {
      return (
        <button
          id={id}
          ref={ref}
          className={className}
          type={type}
          disabled={disabled}
          onClick={onClick}
          data-testid={`${id}-test-id`}
        >
          {children}
        </button>
      );
    }

    if (target === '_blank') {
      // React Router's link does not support external links
      return (
        <a
          id={id}
          className={className}
          href={href!}
          onClick={onClick}
          target={target}
          rel={rel}
          data-testid={`${id}-test-id`}
        >
          {children}
        </a>
      );
    }
    return (
      <Link id={id} className={className} to={href!} onClick={onClick} data-testid={`${id}-test-id`}>
        {children}
      </Link>
    );
  }
);
