import { Fragment, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { BackOfficeLayout } from './BackOfficeLayout';

export const BackOfficeLayoutRoute = () => {
  // TODO: better suspense fallback
  return (
    <BackOfficeLayout>
      <Suspense fallback={<Fragment />}>
        <Outlet />
      </Suspense>
    </BackOfficeLayout>
  );
};
