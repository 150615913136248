import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/work-sans/400.css';
import '@fontsource/work-sans/500.css';
import '@fontsource/work-sans/600.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import 'react-tooltip/dist/react-tooltip.css';
import { CompensationApp } from './CompensationApp';
import reportWebVitals from './meta/reportWebVitals';
import { PlausibleAnalytics } from './meta/PlausibleAnalytics';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://8f21e5d4cdfd492e9efa50e9d3569a46@o4504162323398656.ingest.sentry.io/4504162330869760',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <PlausibleAnalytics />
    <BrowserRouter>
      <CompensationApp />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
