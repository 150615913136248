import classNames from 'classnames';
import React from 'react';
import { Row, TableInstance } from 'react-table';
import { Header, HeaderSize } from '../header/Header';
import { Loader, LoaderSize } from '../loader/Loader';
import { Pagination } from './Pagination';

export function Table<T extends object>({
  table: {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    visibleColumns,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
    page,
    nextPage,
    pageCount,
    gotoPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  },
  totalCount,
  onRowClick,
  isLoading,
  noDataMessage,
  renderRowSubComponent,
}: {
  table: TableInstance<T>;
  totalCount: number;
  onRowClick?: (rowItem: T) => void;
  isLoading?: boolean;
  noDataMessage?: string;
  renderRowSubComponent?: (props: { row: Row<T> }) => React.ReactNode;
}) {
  return (
    <div className="flow-root">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="border rounded-lg">
            <table className="min-w-full divide-y divide-gray-300 overflow-hidden" {...getTableProps()}>
              <thead className="bg-gray-50">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm sm:pl-6 font-medium uppercase"
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className="divide-y divide-gray-200 bg-white [&>*:nth-child(odd)]:bg-slate-100"
                {...getTableBodyProps()}
              >
                {isLoading || !rows?.length ? (
                  <tr>
                    <td colSpan={100} className="text-center">
                      {(isLoading && <Loader size={LoaderSize.LARGE} />) ||
                        (noDataMessage && (
                          <Header size={HeaderSize.LARGE} className="text-gray-400 my-8">
                            {noDataMessage}
                          </Header>
                        ))}
                    </td>
                  </tr>
                ) : (
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <React.Fragment key={row.id}>
                        <tr
                          {...row.getRowProps()}
                          onClick={() => onRowClick && onRowClick(row.original)}
                          className={classNames(onRowClick && 'cursor-pointer')}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td className="py-4 pl-4 pr-3 text-sm sm:pl-6" {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                        {row.isExpanded && renderRowSubComponent ? (
                          <tr className="bg-slate-200 shadow-inner">
                            <td className="py-4 px-6" colSpan={visibleColumns.length}>
                              {renderRowSubComponent({ row })}
                            </td>
                          </tr>
                        ) : null}
                      </React.Fragment>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination
        totalCount={totalCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={pageCount}
        nextPage={nextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
      />
    </div>
  );
}
