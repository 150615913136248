export const activePolicy = {
  strictlyNecessaryCookies: [
    {
      title: 'Session',
      type: 'Cookie',
      names: ['sessionId'],
      lifespan: '1 day',
      purpose:
        'We use the sessionId cookie to maintain a persistent authenticated session with Compensation.app after a user signs in.',
    },
    {
      title: 'Cookie consent',
      type: 'Local storage item',
      names: ['cookieConsent', '__mp_opt_in_out_#'],
      lifespan: 'not bounded',
      purpose:
        'We use the cookieConsent local storage item and mixpanel uses the __mp_opt_in_out_# local storage item to remember your decision on using or not using optional cookies.',
    },
  ],
  improvementCookies: [
    {
      title: 'Mixpanel',
      type: 'Cookie',
      vendor: 'Mixpanel',
      lifespan: '1 year',
      names: ['mp_#_mixpanel'],
      purpose:
        "The mp_#_mixpanel cookie is an analytical cookie that helps us understand how you move through the website. This data is shared with Mixpanel in order to help us improve our site. With it we can measure visits to parts of our site, track the success of flows within our site, understand where the failures or dropoffs are, and measure the success of changes to the site. As well as referral source information, it contains a randomly assigned number in order to associate events belonging to the same session, and if logged in, the user's id.",
      extraInformationLink: 'https://mixpanel.com/legal/privacy-policy/',
    },
  ],
  lastModificationDate: parseDate('26/11/2022'),
};

function parseDate(string: string): Date {
  const [day, month, year] = string.split('/');
  return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
}
