import { Close } from '../icons/Icons';
import { AnimatePresence, motion } from 'framer-motion';

export default function Toast({
  id,
  isVisible,
  children,
  onClose,
}: {
  id?: string;
  isVisible: boolean;
  children: React.ReactNode;
  onClose: () => void;
}) {
  return (
    <>
      <div aria-live="assertive" className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:p-6 z-50">
        <AnimatePresence initial={false} mode="popLayout">
          {isVisible && (
            <motion.div
              key={id}
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 100, opacity: 0 }}
              className="flex w-full flex-col items-center space-y-4 mb-12"
            >
              <div className="pointer-events-auto w-full max-w-sm rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 p-4">
                <div className="flex items-center justify-between">
                  <div className="break-words">{children}</div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                      onClick={onClose}
                    >
                      <span className="sr-only">Close</span>
                      <Close className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}
