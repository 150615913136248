import { Fragment, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useCanUseOptionalCookies } from '../app/cookies/CookieConsent';

const MIXPANEL_KEY = 'adbe52b21fa0634c3eb97733146315b9';

export const MixpanelController = () => {
  const canUseOptionalCookies = useCanUseOptionalCookies();

  useEffect(() => {
    mixpanel.init(MIXPANEL_KEY, {
      opt_out_tracking_by_default: false,
      opt_out_persistence_by_default: false,
      // host data in the EU
      api_host: 'https://api-eu.mixpanel.com',

      // turn on debug mode and ignore browser Do Not Track setting locally
      debug: process.env.NODE_ENV !== 'production',
      ignore_dnt: process.env.NODE_ENV !== 'production',
    });
  }, []);

  useEffect(() => {
    if (canUseOptionalCookies && !mixpanel.has_opted_in_tracking()) {
      mixpanel.opt_in_tracking();
    } else if (mixpanel.has_opted_in_tracking()) {
      mixpanel.opt_out_tracking();
    }
  }, [canUseOptionalCookies]);

  return <Fragment />;
};
