import { useState } from 'react';
import { Button } from '../button/Button';
import { Header } from '../header/Header';
import { Modal, ModalSize } from '../modal/Modal';

export const ConfirmationModal = ({
  title,
  open,
  onClose,
  children,
  onConfirm,
}: {
  title?: string;
  open: boolean;
  onClose: () => any;
  children: React.ReactNode;
  onConfirm: () => Promise<any>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal open={open} onClose={onClose} size={ModalSize.SMALL}>
      {title && <Header>{title}</Header>}
      {children}
      <div className="flex gap-4 mt-6">
        <Button
          primary
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            onConfirm().finally(() => {
              setIsLoading(false);
              onClose();
            });
          }}
        >
          Confirm
        </Button>
        <Button disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
