import { ImportRecord } from '../../api/types';

export function getLocationString({ country, city, locationName }: ImportRecord): string | undefined {
  if (locationName && !country && !city) {
    return locationName;
  }
  const locations: string[] = [];
  country && locations.push(country);
  city && locations.push(city);
  return locations.length ? locations.join(' - ') : '-';
}
