import React, { Fragment, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AppLayout } from './AppLayout';

export const AppLayoutRoute = () => {
  // TODO: better suspense fallback
  return (
    <AppLayout>
      <Suspense fallback={<Fragment />}>
        <Outlet />
      </Suspense>
    </AppLayout>
  );
};
