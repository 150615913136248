import React from 'react';
import { useField } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';

import { Input, InputProps, InputSize } from '../input/Input';
import classNames from 'classnames';
import { TextArea, TextAreaProps, TextAreaSize } from '../textarea/TextArea';
import { Combobox, ComboboxProps, ComboboxOption, ComboboxSize } from '../combobox/Combobox';

export enum FieldSize {
  SMALL = 'SM',
  MEDIUM = 'MD',
  LARGE = 'LG',
}

export enum FieldType {
  MULTILINE = 'MULTILINE',
  INPUT = 'INPUT',
  COMBOBOX = 'COMBOBOX',
  CHECKBOX = 'CHECKBOX',
}

type FieldTypeValue<IsMultiCombobox extends boolean> =
  | ({ fieldType: FieldType.COMBOBOX } & Omit<ComboboxProps<IsMultiCombobox>, 'size' | 'type'>)
  | ({ fieldType: FieldType.MULTILINE } & Omit<TextAreaProps, 'size' | 'type'>)
  | ({ fieldType: FieldType.CHECKBOX } & Omit<InputProps, 'size' | 'type'>)
  | ({ fieldType: FieldType.INPUT } & Omit<InputProps, 'size' | 'type'>);

export type FieldProps<IsMultiCombobox extends boolean = false> = {
  id: string;
  label?: React.ReactNode;
  errorMessage?: React.ReactNode;
  size?: FieldSize;
  type?: InputProps['type'] | 'checkbox';
} & FieldTypeValue<IsMultiCombobox>;

const labelSizeClasses = {
  [FieldSize.SMALL]: 'text-xs',
  [FieldSize.MEDIUM]: 'text-sm',
  [FieldSize.LARGE]: 'text-base',
};

export function Field<IsMultiCombobox extends boolean = false>({
  id,
  label,
  errorMessage,
  className,
  size = FieldSize.MEDIUM,
  ...inputElementProps
}: FieldProps<IsMultiCombobox>) {
  const validationMessage = <FieldErrorMessage errorMessage={errorMessage} size={size} />;
  // TODO: move out to design system components
  if (inputElementProps.fieldType === FieldType.CHECKBOX) {
    return (
      <div className={classNames('mb-4', className)}>
        <div className="flex align-middle">
          <label htmlFor={id} className={getFieldLabelClasses(size)}>
            <input
              id={id}
              type="checkbox"
              className="h-4 w-4 mr-2 rounded border-slate-300 text-slate-600 focus:text-slate-500 ring-slate-500"
              {...inputElementProps}
            />
            {label}
          </label>
        </div>
        {validationMessage}
      </div>
    );
  }

  let inputElement;
  switch (inputElementProps.fieldType) {
    case FieldType.MULTILINE:
      inputElement = <TextArea {...inputElementProps} id={id} block size={size as unknown as TextAreaSize} />;
      break;
    case FieldType.COMBOBOX:
      inputElement = (
        <Combobox className="mb-0" {...inputElementProps} id={id} size={size as unknown as ComboboxSize} />
      );
      break;
    default:
      let { fieldType: _, ...inputElementPropsWithoutFieldType } = inputElementProps;
      inputElement = (
        <Input
          {...(inputElementPropsWithoutFieldType as InputProps)}
          id={id}
          block
          size={size as unknown as InputSize}
        />
      );
  }

  return (
    <div className={classNames('mb-4', className)}>
      {label ? (
        <label htmlFor={id} className={getFieldLabelClasses(size)}>
          {label}
        </label>
      ) : (
        ''
      )}
      {inputElement}
      {validationMessage}
    </div>
  );
}

export type FormikFieldProps = {
  name: string;
  id?: string;
  label?: React.ReactNode;
  validate?: (value: any) => undefined | string | Promise<any>;

  // input props
  type?: InputProps['type'] | 'checkbox';
  className?: InputProps['className'];
  autoFocus?: InputProps['autoFocus'];
  disabled?: InputProps['disabled'];
  size?: FieldSize;
  fieldType?: FieldType;

  // combobox props
  options?: ComboboxOption[];
  optionsContainerClassName?: string;
  isClearable?: boolean;
};

export const FormikField: React.FunctionComponent<FormikFieldProps> = ({
  name,
  validate,
  options = [],
  ...fieldProps
}) => {
  const [formikFieldProps, meta, { setValue }] = useField({ name, validate });

  const id = fieldProps.id || name;

  let fProps;
  if (fieldProps.fieldType === FieldType.COMBOBOX) {
    fProps = {
      ...formikFieldProps,
      isDisabled: fieldProps.disabled,
      // React.ChangeEvent<any> is in the signature so TS wouldn't complain
      onChange: (option: ComboboxOption | null | React.ChangeEvent<any>) => {
        if (!option) {
          setValue(null);
          return;
        }
        setValue(option);
      },
    };
  } else {
    fProps = formikFieldProps;
  }

  return (
    <Field
      {...fProps}
      {...fieldProps}
      fieldType={fieldProps.fieldType || FieldType.INPUT}
      options={options}
      id={id}
      errorMessage={meta.error && meta.touched && meta.error}
    />
  );
};
export function FieldErrorMessage({
  errorMessage,
  size = FieldSize.MEDIUM,
}: {
  errorMessage: React.ReactNode;
  size?: FieldSize;
}) {
  return (
    <AnimatePresence>
      {errorMessage && (
        <motion.div
          className={classNames('mt-1', labelSizeClasses[size])}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -15 }}
        >
          ☝️ {errorMessage}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export function getFieldLabelClasses(size: FieldSize) {
  return classNames('ml-px block mb-1 text-gray-700', labelSizeClasses[size]);
}
