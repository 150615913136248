import { Hamburger } from '../../design/logo/Logo';

export const HamburgerMenuButton = ({ onClick }: { onClick: () => unknown }) => (
  <button
    type="button"
    className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
    onClick={onClick}
  >
    <span className="sr-only">Open sidebar</span>
    <Hamburger className="h-6 w-6" aria-hidden="true" />
  </button>
);
