import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useUserCompanies } from '../api/hooks';
import { Company } from '../api/types';

const ActiveCompanyContext = createContext<{ company?: Company; setActiveCompany: (company: Company) => void }>({
  company: undefined,
  setActiveCompany() {}, // noop
});

export const ActiveCompanyProvider: React.FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const { companies } = useUserCompanies();
  const [activeCompany, setActiveCompany] = useState<Company | undefined>(companies?.[0]);

  useEffect(() => {
    const isValidActiveCompany = !!activeCompany && !!companies?.find((c) => c.id === activeCompany.id);
    if (!isValidActiveCompany) {
      setActiveCompany(companies?.[0]);
    }
  }, [companies, activeCompany]);

  return (
    <ActiveCompanyContext.Provider
      value={{
        company: activeCompany,
        setActiveCompany,
      }}
    >
      {children}
    </ActiveCompanyContext.Provider>
  );
};

export const useActiveCompany = () => useContext(ActiveCompanyContext).company;
export const useSetActiveCompany = () => useContext(ActiveCompanyContext).setActiveCompany;
