import { useLogout, useSelf, useUserCompanies } from '../../api/hooks';
import { NavigationLink } from './NavigationLink';
import { Person as PersonIcon } from '../../design/icons/Icons';
import { MobileSidebar } from './MobileSidebar';
import { DesktopSidebar } from './DesktopSidebar';
import { useActiveCompany, useSetActiveCompany } from '../../authentication/ActiveCompany';
import { Combobox, ComboboxOption, ComboboxSize } from '../../design/combobox/Combobox';
import { companyToComboboxOption } from '../../design/combobox/optionTransformers';

export const SidebarContainer = ({
  open,
  onClose,
  navigationItems,
}: {
  open: boolean;
  onClose: () => unknown;
  navigationItems: SidebarNavigationItem[];
}) => {
  const navChildren = navigationItems.map((item) => (
    <NavigationLink key={item.href} to={item.href} disabled={item.disabled} className="md:text-sm">
      {item.name}
    </NavigationLink>
  ));

  const authContextManagement = (
    <div className="flex flex-col gap-4 w-full">
      <CompanyContextDropdown />
      <Profile />
    </div>
  );

  return (
    <>
      <MobileSidebar
        sidebarOpen={open}
        onClose={onClose}
        navChildren={navChildren}
        footerChildren={authContextManagement}
      />
      <DesktopSidebar navChildren={navChildren} footerChildren={authContextManagement} />
    </>
  );
};

function Profile() {
  const self = useSelf();
  const logout = useLogout();

  return (
    <button onClick={logout} className="group block flex-shrink-0 text-left md:w-full">
      <div className="flex items-center">
        <div>
          <PersonIcon className="inline-block h-9 w-9 rounded-full" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-semibold text-slate-700 group-hover:text-slate-900">{self ? self.name : ''}</p>
          <p className="text-xs font-semibold text-slate-500 group-hover:text-slate-700">Sign out</p>
        </div>
      </div>
    </button>
  );
}

function CompanyContextDropdown() {
  const { companies } = useUserCompanies();
  const activeCompany = useActiveCompany();
  const setActiveCompany = useSetActiveCompany();

  const updateActiveCompany = (companyOption: ComboboxOption | null) => {
    if (!companyOption) {
      return;
    }
    const selectedCompany = companies?.find((c) => c.id === companyOption.id);
    setActiveCompany(selectedCompany!);
  };

  return (companies?.length || 0) > 1 ? (
    <Combobox
      id="active-company"
      label="Company"
      className="w-full"
      size={ComboboxSize.SMALL}
      options={companies?.map(companyToComboboxOption) || []}
      value={activeCompany ? companyToComboboxOption(activeCompany) : null}
      onChange={updateActiveCompany}
      isClearable={false}
    />
  ) : (
    <></>
  );
}

export interface SidebarNavigationItem {
  name: string;
  href: string;
  icon: any; // TODO: Add type
  disabled?: boolean;
}
