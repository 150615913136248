import { Formik } from 'formik';
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { number, object } from 'yup';
import {
  currencyCodeFromString,
  EmploymentsImportType,
  ImportRecord,
  ImportRecordStatus,
  ImportRecordWithLabels,
  LabelImportRecordCommand,
  Money,
  RequestRecordVerificationCommand,
} from '../../api/types';
import { formatMoney } from '../../app/util/formatUtils';
import { Button } from '../../design/button/Button';
import {
  comboboxOptionShape,
  currencyToComboboxOption,
  locationToComboboxOption,
  positionToComboboxOption,
  seniorityToComboboxOption,
} from '../../design/combobox/optionTransformers';
import { ConfirmationModal } from '../../design/confirmationModal/ConfirmationModal';
import { Header } from '../../design/header/Header';
import { Verified } from '../../design/icons/Icons';
import { Labels, LabelsFormFields } from '../../app/common/importRecordLabels/LabelFormFields';
import { Field, FieldType } from '../../design/field/Field';

export function ImportRecordLabels({
  importRecord,
  isLabeling,
  labelingActions,

  employmentsImportType,
}: {
  importRecord: ImportRecordWithLabels;
  isLabeling: boolean;
  labelingActions: LabelingActions;

  employmentsImportType?: EmploymentsImportType;
}) {
  return (
    <div className="flex flex-col">
      <div className="flex gap-4 items-center mb-6">
        <Header className="mb-0">Labels</Header>
        {importRecord?.labels?.suggestedBy && (
          <div>
            <Verified
              data-tooltip-id={importRecord.labels.id}
              className="fill-brandGreen-mid text-2xl cursor-pointer"
            />
            <Tooltip id={importRecord.labels.id} content={'Verified by company'}></Tooltip>
          </div>
        )}
      </div>
      <>
        <Formik
          key={importRecord.id}
          initialValues={initializeValues(importRecord)}
          validateOnMount={true}
          validationSchema={object().shape({
            location: comboboxOptionShape
              .required('Please add a work location')
              .typeError('Please add a work location'),
            position: comboboxOptionShape.required('Please add a position').typeError('Please add a position'),
            seniority: comboboxOptionShape.required('Please add a seniority').typeError('Please add a seniority'),
            salaryCurrency: comboboxOptionShape.required('Please add a currency').typeError('Please add a currency'),
            yearlySalary: number()
              .moreThan(0, 'Salary must be greater than 0')
              .required('Please add a salary')
              .typeError('Please add a salary'),
          })}
          onSubmit={async ({ position, seniority, location, yearlySalary, salaryCurrency }) => {
            const { labelImportRecord } = labelingActions;
            await labelImportRecord({
              importRecordId: importRecord!.id,
              seniorityId: seniority!.id as string,
              positionId: position!.id as string,
              locationId: location!.id as string,
              yearlySalary: { value: yearlySalary, currency: currencyCodeFromString(salaryCurrency!.value) },
            });
          }}
        >
          {({ values, setFieldValue, isValid, isSubmitting }) => (
            <>
              <LabelsFormFields values={values} setFieldValue={setFieldValue}>
                {!!importRecord.labels?.suggestionMessage && (
                  <Field
                    id="suggestion-message"
                    label="Client notes"
                    rows={2}
                    disabled={true}
                    fieldType={FieldType.MULTILINE}
                    value={importRecord.labels?.suggestionMessage}
                  />
                )}
                <div className="mt-6">
                  <LabelingActionButtons
                    employmentsImportType={employmentsImportType}
                    importRecord={importRecord}
                    isValid={isValid}
                    labels={values}
                    isLoading={isSubmitting || isLabeling}
                    {...labelingActions}
                  />
                </div>
              </LabelsFormFields>
            </>
          )}
        </Formik>
      </>
    </div>
  );
}

function LabelingActionButtons({
  importRecord,
  labels,
  isLoading,
  isValid,

  skipImportRecord,
  terminateImportRecord,
  requestRecordVerification,

  employmentsImportType,
}: {
  importRecord: ImportRecord;
  labels: Labels;
  isLoading: boolean;
  isValid: boolean;

  employmentsImportType?: EmploymentsImportType;
} & LabelingActions) {
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const { salaryCurrency, yearlySalary } = labels;
  const isSalaryDefined = !!yearlySalary && !!salaryCurrency;
  const isRecordInReview = importRecord?.status === ImportRecordStatus.TO_BE_REVIEWED;

  const hasValidImportedId = employmentsImportType === EmploymentsImportType.INTEGRATION && importRecord.importedId;
  return (
    <div className="flex justify-between mt-4">
      <div className="flex gap-4">
        <Button disabled={!isValid || isLoading} primary type="submit">
          Confirm
        </Button>
        <Button
          disabled={isLoading || !isRecordInReview}
          onClick={() => {
            skipImportRecord(importRecord!.id);
          }}
        >
          Skip
        </Button>
      </div>
      <div className="flex gap-4">
        {hasValidImportedId && (
          <Button
            disabled={!isSalaryDefined || isLoading}
            onClick={() => {
              setConfirmationOpen(true);
            }}
          >
            Request Verification
          </Button>
        )}
        <Button
          disabled={isLoading}
          onClick={() => {
            terminateImportRecord(importRecord!.id);
          }}
        >
          Terminate
        </Button>
      </div>
      <RequestConfirmationModal
        labels={labels}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={async () => requestRecordVerification(labelsToConfirmationRequest(importRecord!, labels))}
      />
    </div>
  );
}

function RequestConfirmationModal({
  labels: { position, location, salaryCurrency, seniority, yearlySalary },

  ...modalState
}: {
  labels: Labels;

  open: boolean;
  onClose: () => any;
  onConfirm: () => Promise<any>;
}) {
  return (
    <ConfirmationModal title={'Confirmation request'} {...modalState}>
      <p className="mb-4">You're about to request record confirmation from the client with the following details:</p>
      <ul className="list-disc list-inside">
        {position && <li>Position - {position.value}</li>}
        {location && <li>Location - {location.value}</li>}
        {seniority && <li>Seniority - {seniority.value}</li>}
        {yearlySalary && salaryCurrency && (
          <li>
            Salary - {formatMoney({ value: yearlySalary, currency: currencyCodeFromString(salaryCurrency.value) })}
          </li>
        )}
      </ul>
    </ConfirmationModal>
  );
}

function initializeValues(importRecord: ImportRecordWithLabels): Labels {
  const salary = initializeSalary(importRecord);
  return {
    position: importRecord?.labels?.position ? positionToComboboxOption(importRecord.labels.position) : null,
    seniority: importRecord?.labels?.seniority ? seniorityToComboboxOption(importRecord.labels.seniority) : null,
    location: importRecord?.labels?.location ? locationToComboboxOption(importRecord.labels.location, false) : null,
    salaryCurrency: salary ? currencyToComboboxOption(salary.currency) : null,
    yearlySalary: salary?.value || 0,
  };
}

function initializeSalary({ payPeriod, salary, labels }: ImportRecordWithLabels): Money | undefined {
  if (labels?.yearlySalary) {
    return labels.yearlySalary;
  }

  if (!salary) {
    return;
  }

  if (payPeriod === 'YEAR') {
    return salary;
  }

  if (payPeriod === 'MONTH') {
    return { ...salary, value: salary.value * 12 };
  }
}

function labelsToConfirmationRequest(
  importRecord: ImportRecord,
  { location, position, seniority, salaryCurrency, yearlySalary }: Labels
): RequestRecordVerificationCommand {
  return {
    importRecordId: importRecord.id,
    locationId: location?.id.toString(),
    positionId: position?.id.toString(),
    seniorityId: seniority?.id.toString(),
    yearlySalary: { value: yearlySalary, currency: currencyCodeFromString(salaryCurrency!.value) },
  };
}

export interface LabelingActions {
  labelImportRecord: (command: LabelImportRecordCommand) => Promise<ImportRecord>;
  skipImportRecord: (importRecordId: string) => Promise<ImportRecord>;
  terminateImportRecord: (importRecordId: string) => Promise<ImportRecord>;
  requestRecordVerification: (command: RequestRecordVerificationCommand) => Promise<ImportRecordWithLabels>;
}
