import React from 'react';

import { useHasRoles, useNavigateToDefaultRoute } from '../api/hooks';
import { RoleName } from '../api/types';
import { Loader, LoaderSize } from '../design/loader/Loader';

export interface AuthenticatedProps {
  requiredRole: RoleName;
  children?: React.ReactNode;
}

export const Authorized: React.FunctionComponent<AuthenticatedProps> = ({ requiredRole, children }) => {
  const { hasRoles, isLoading } = useHasRoles([requiredRole]);
  const navigateToDefault = useNavigateToDefaultRoute();

  if (isLoading) {
    return <Loader size={LoaderSize.LARGE} />;
  }

  if (!hasRoles) {
    navigateToDefault();
    return <></>;
  }

  return <>{children}</>;
};
