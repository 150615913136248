import mixpanel from 'mixpanel-browser';
import { EmploymentsImportStatus, HRISIntegration, User } from '../api/types';

export function trackUserSignup(user: User) {
  mixpanel.identify(user.id);
  mixpanel.track('User created');
}

export function trackCompanyCreated() {
  mixpanel.track('Company created');
}

export function trackIntegrationDone(integration: HRISIntegration) {
  mixpanel.track('Integration created', {
    integrationType: integration.type,
    hris: integration.hris,
  });
}

export function trackCSVUploaded() {
  mixpanel.track('CSV uploaded');
}

export function trackOnboardingCompleted(employmentsImportStatus: EmploymentsImportStatus) {
  mixpanel.track('Onboarding completed', {
    employmentsImportStatus,
  });
}
