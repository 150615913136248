import { Dialog } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, ButtonSize } from '../../design/button/Button';
import { activePolicy } from './activeCookiePolicy';

const CookieConsentDecisionContext = React.createContext<CookieConsentDecisionContextType>({
  // placeholder until initial value provided
  decision: null,
  setDecision: () => null,
});

export const CookieConsentProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const consent = useCookieConsent();
  return (
    <CookieConsentDecisionContext.Provider value={consent}>
      <CookieConsentQuestion consent={consent} />
      {children}
    </CookieConsentDecisionContext.Provider>
  );
};

const CookieConsentQuestion: React.FunctionComponent<{ consent: CookieConsentDecisionContextType }> = ({ consent }) => {
  const initialFocusRef = useRef<HTMLButtonElement | null>(null);
  const location = useLocation();

  const needsToMakeADecision = !consent.decision || (consent.decision.agreed && !decisionStillValid(consent.decision));
  const shouldShow = location.pathname !== '/cookies' && needsToMakeADecision;
  return (
    <AnimatePresence initial={false}>
      {shouldShow ? (
        <Dialog
          key="dialog"
          static
          open={shouldShow}
          onClose={() => null}
          className="relative z-50"
          initialFocus={initialFocusRef}
        >
          <motion.div
            className="fixed inset-0 bg-gray-500"
            aria-hidden="true"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.75 }}
            exit={{ opacity: 0 }}
          />
          <div className="fixed inset-0 flex items-end justify-center px-4 pt-4">
            <Dialog.Panel
              initial={{ opacity: 0, y: 200 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 200 }}
              as={motion.div}
              className="mx-auto rounded-lg bg-white p-4 shadow-xl my-4 w-full sm:max-w-3xl sm:p-6 flex flex-col sm:flex-row gap-4"
            >
              <div>
                <Dialog.Title className="text-lg font-semibold leading-6 text-gray-900 mb-4">
                  Do you accept optional cookies?
                </Dialog.Title>
                These let us give you a better experience and give us info to improve our product. They aren't used for
                marketing. We won't use them unless you'd like us to.{' '}
                <Link className="a" to="/cookies">
                  Learn more
                </Link>
              </div>
              <div>
                <Button
                  id="acceptCookies"
                  ref={initialFocusRef}
                  size={ButtonSize.LARGE}
                  primary
                  block
                  className="mb-2"
                  onClick={() => consent.setDecision(true)}
                >
                  Accept
                </Button>
                <Button id="declineCookies" size={ButtonSize.LARGE} block onClick={() => consent.setDecision(false)}>
                  Decline
                </Button>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      ) : (
        ''
      )}
    </AnimatePresence>
  );
};

function decisionStillValid(decision: CookieConsentDecision): boolean {
  if (!decision.agreed) {
    return true;
  }
  return decision.decisionMadeAt > activePolicy.lastModificationDate;
}

export const useCanUseOptionalCookies: () => boolean = () => {
  const consent = useContext(CookieConsentDecisionContext);
  return !!consent.decision && consent.decision.agreed && decisionStillValid(consent.decision);
};

interface CookieConsentDecisionContextType {
  decision: CookieConsentDecision | null;
  setDecision: (decision: boolean) => void;
}

const useCookieConsent: () => CookieConsentDecisionContextType = () => {
  const [decision, setState] = useState(loadCookieConsentDecision());
  return {
    decision,
    setDecision(decision: boolean) {
      const newDecision: CookieConsentDecision = { agreed: decision, decisionMadeAt: new Date() };
      saveCookieConsentDecision(newDecision);
      setState(newDecision);
    },
  };
};

const COOKIE_DECISION_KEY = 'cookieConsent';
interface CookieConsentDecision {
  agreed: boolean;
  decisionMadeAt: Date;
}

function loadCookieConsentDecision(): CookieConsentDecision | null {
  const item = localStorage.getItem(COOKIE_DECISION_KEY);
  if (!item) {
    return null;
  }
  try {
    const decision = JSON.parse(item);
    const decisionMadeAt = new Date(decision.decisionMadeAt);
    if (!(decisionMadeAt instanceof Date)) {
      return null;
    }
    return {
      agreed: decision.agreed,
      decisionMadeAt,
    };
  } catch (e) {
    return null;
  }
}

function saveCookieConsentDecision(decision: CookieConsentDecision) {
  localStorage.setItem(COOKIE_DECISION_KEY, JSON.stringify(decision));
}

export interface CookiePolicy {
  strictlyNecessaryCookies: Cookie[];
  improvementCookies: Cookie[];
  lastModificationDate: Date;
}

export interface Cookie {
  title: string;
  vendor?: string;
  lifespan: string;
  names: string[];
  purpose: string;
  extraInformationLink?: string;
}
