import React from 'react';
import classNames from 'classnames';
import { getInputClassNames, InputSize } from '../input/Input';

export enum TextAreaSize {
  SMALL = 'SM',
  MEDIUM = 'MD',
  LARGE = 'LG',
}

export interface TextAreaProps {
  id?: string;
  name?: string;
  size?: TextAreaSize;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  value: string;
  disabled?: boolean;
  rows?: number;

  block?: boolean;
  className?: string;
  autoFocus?: boolean;
}

export const TextArea: React.FunctionComponent<TextAreaProps> = ({
  id,
  name,
  size = TextAreaSize.MEDIUM,
  onChange,
  onBlur,
  value,
  block,
  rows,
  className: customClassName,
  ...rest
}) => {
  const className = classNames(getInputClassNames({ block, size: size as unknown as InputSize }), customClassName);

  return (
    <textarea
      id={id}
      rows={rows || 4}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      {...rest}
      className={className}
      value={value}
    />
  );
};
