// import { useSubscription } from '../../api/hooks';
import { SubscriptionType } from '../../api/types';

export function useCompanyCapabilities(companyId?: string): Capabilities {
  const { isLoading, activeType } = useActiveSubscriptionType(companyId);

  const capabilities: Capabilities = {
    isLoading,
    canUseAdvancedFilters: false,
    canOnboardWithoutImporting: false,
    canInviteUsers: false,
    canSeeOrganizationBenchmarks: false,
  };

  if (activeType === SubscriptionType.PREMIUM_PRIVATE) {
    capabilities.canOnboardWithoutImporting = true;
  }

  if (activeType === SubscriptionType.PREMIUM || activeType === SubscriptionType.PREMIUM_PRIVATE) {
    capabilities.canUseAdvancedFilters = true;
    capabilities.canInviteUsers = true;
    capabilities.canSeeOrganizationBenchmarks = true;
  }

  return capabilities;
}

export interface Capabilities {
  isLoading: boolean;
  canUseAdvancedFilters: boolean;
  canOnboardWithoutImporting: boolean;
  canInviteUsers: boolean;
  canSeeOrganizationBenchmarks: boolean;
}

export function useActiveSubscriptionType(companyId?: string): SubscriptionStatus {
  // const { subscription, isLoading } = useSubscription(companyId);
  // const activeType = subscription?.isActive ? subscription.type : null;
  // return { isLoading, activeType };
  // TODO: uncomment when subscription is ready to go live
  return { isLoading: false, activeType: SubscriptionType.PREMIUM };
}

export interface SubscriptionStatus {
  isLoading: boolean;
  activeType: SubscriptionType | null;
}

export function getSubscriptionMailToLink({
  plan,
  frequency,
}: {
  plan: SubscriptionType | null;
  frequency?: 'monthly' | 'annual';
}): string {
  let link = 'mailto:raido@meetfrank.com';
  if (!plan) {
    link += `?subject=${encodeURIComponent('Compensation App Plans - Unsubscribe')}&body=${encodeURIComponent(
      'Please unsubscribe me from Compensation App.'
    )}`;
  } else {
    link += `?subject=${encodeURIComponent(`Compensation App Plans - Subscribe to ${plan}`)}&body=${encodeURIComponent(
      `Please sign me up for ${plan.toLowerCase()}.${frequency ? ` I would like the ${frequency} payment plan.` : ''}`
    )}`;
  }

  return link;
}
