import {
  MdWarning as WarningIcon,
  MdClose as CloseIcon,
  MdOutlineError as ErrorIcon,
  MdPerson as PersonIcon,
  MdCheckCircle as CheckedCircleIcon,
  MdCheck,
  MdInfo,
  MdMode,
  MdOutlineExpandMore,
  MdChevronLeft,
  MdChevronRight,
  MdInfoOutline,
  MdCheckCircle,
  MdOutlineWarningAmber,
  MdPeopleAlt,
  MdLocationPin,
  MdOutlineVerified,
} from 'react-icons/md';

import { BsChevronExpand, BsPersonLinesFill } from 'react-icons/bs';
import { TbChartAreaLine } from 'react-icons/tb';
import { HiCash } from 'react-icons/hi';

export const Warning = WarningIcon;
export const Close = CloseIcon;
export const Error = ErrorIcon;
export const Person = PersonIcon;
export const Expand = BsChevronExpand;
export const WarnOutline = MdOutlineWarningAmber;
export const Check = MdCheck;
export const CheckCircle = MdCheckCircle;
export const Info = MdInfo;
export const InfoOutline = MdInfoOutline;
export const Edit = MdMode;
export const Success = CheckedCircleIcon;
export const Verified = MdOutlineVerified;
export const ChevronDown = MdOutlineExpandMore;
export const PositionSelect = BsPersonLinesFill;
export const AreaLineChart = TbChartAreaLine;
export const People = MdPeopleAlt;
export const Cash = HiCash;
export const LocationPin = MdLocationPin;

export const ChevronRight = MdChevronRight;
export const ChevronLeft = MdChevronLeft;
