import mixpanel from 'mixpanel-browser';
import { useCallback, useMemo } from 'react';
import {
  RefetchOptions,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { NavigateOptions, useNavigate, useSearchParams } from 'react-router-dom';
import { useSetAuthenticationState } from '../authentication/Authentication';
import { trackUserSignup } from '../meta/tracking';
import {
  register,
  fetchSelf,
  ApiError,
  logout,
  login,
  fetchLocations,
  fetchPositions,
  fetchHeadcountRanges,
  fetchPositionSeniorities,
  fetchSeniorityBenchmarks,
  fetchDefaultFilters,
  fetchLatestEmploymentsImport,
  fetchIndustries,
  saveCompany,
  requestPasswordReset,
  resetPassword,
  createKomboConnectionLink,
  createKomboIntegration,
  fetchSelfRoles,
  fetchEmploymentsImportAssignees,
  fetchEmploymentsImports,
  fetchImportRecordToLabel,
  labelImportRecord,
  skipImportRecord,
  patchEmploymentsImportAssignee,
  terminateImportRecord,
  fetchEmploymentsImport,
  fetchImportRecords,
  updateEmploymentsImportStatus,
  fetchPublicSeniorityBenchmarks,
  fetchCompanyImportRecords,
  confirmImportRecord,
  requestRecordVerification,
  fetchCompanyEmployments,
  fetchUserCompanies,
  editCompany,
  fetchCompanyStats,
  suggestEmploymentChanges,
  fetchSubscription,
  fetchCompanyUsers,
  fetchRoles,
  inviteUser,
  fetchOnboardingState,
  fetchInvitation,
  acceptInvitation,
  removeUserFromCompany,
  fetchIntercomSettings,
} from './api';
import {
  AuthenticationState,
  SeniorityBenchmark,
  SeniorityBenchmarksQuery,
  DefaultFilters,
  EmploymentsImport,
  HeadcountRange,
  Location,
  LoginCommand,
  Position,
  RegisterCommand,
  Seniority,
  User,
  Industry,
  SaveCompanyCommand,
  Company,
  RequestPasswordResetCommand,
  ResetPasswordCommand,
  KomboConnectionLink,
  HRISIntegration,
  UserRole,
  FilterEmploymentsImportsQuery,
  EmploymentsImportListItem,
  EmploymentsImportAssignee,
  ImportRecord,
  LabelImportRecordCommand,
  PatchEmploymentsImportAssigneeCommand,
  PositionSenioritiesQuery,
  LocationsQuery,
  FilterImportRecordsQuery,
  ImportRecordWithLabels,
  EmploymentsImportStatusUpdateCommand,
  EmploymentsImportStatus,
  FilterCompanyImportRecordsQuery,
  ConfirmImportRecordCommand,
  RequestRecordVerificationCommand,
  PaginationResult,
  FilterCompanyEmploymentsQuery,
  Employment,
  EditCompanyCommand,
  CompanyStats,
  SuggestEmploymentChangesCommand,
  Role,
  InviteUserCommand,
  Invitation,
  Subscription,
  OnboardingState,
  IntercomSettings,
} from './types';

export const QueryKey = {
  SELF: ['self'],
  SELF_ROLES: ['self', 'roles'],
  POSITIONS: ['positions'],
  INDUSTRIES: ['industries'],
  LOCATIONS: (query: LocationsQuery) => ['locations', query],
  HEADCOUNT_RANGES: ['headcountRanges'],
  SENIORITIES: (positionId?: string, query?: PositionSenioritiesQuery) => [
    'positions',
    positionId,
    'seniorities',
    query,
  ],
  BASE_SENIORITIES: (positionId?: string) => ['positions', positionId, 'seniorities', 'base'],
  DEFAULT_FILTERS: ['defaultFilters'],
  EMPLOYMENTS_IMPORT: (importId: string) => ['employmentsImports', importId],
  EMPLOYMENTS_IMPORTS: (query: FilterEmploymentsImportsQuery) => ['employmentsImports', 'filter', query],
  EMPLOYMENTS_IMPORT_RECORD_TO_LABEL: (employmentsImportId?: string, importRecordId?: string) => [
    'employmentsImports',
    employmentsImportId,
    'record-to-label',
    importRecordId,
  ],
  COMPANY_EMPLOYMENTS_IMPORTS: (companyId?: string) => ['companies', companyId, 'employmentsImports'],
  COMPANY_STATS: (companyId?: string) => ['companies', companyId, 'stats'],
  ROLES: ['roles'],
  USER_COMPANIES: ['me', 'companies'],
  ONBOARDING: (companyId?: string) => ['onboarding', companyId],
  INVITATION: (token?: string) => ['invitations', token],
  COMPANY_USERS: (companyId?: string) => ['companies', companyId, 'users'],
  COMPANY_EMPLOYMENTS: (filter: FilterCompanyEmploymentsQuery) => [
    'companies',
    filter.companyId,
    'employments',
    filter,
  ],
  EMPLOYMENTS_IMPORT_ASSIGNEES: ['employmentsImports', 'assignees'],
  LATEST_EMPLOYMENTS_IMPORT: (companyId?: string) => ['companies', companyId, 'employmentsImports', 'latest'],
  IMPORT_RECORDS: (query: FilterImportRecordsQuery) => [
    'employmentsImports',
    query.employmentsImportId,
    'records',
    query,
  ],
  COMPANY_IMPORT_RECORDS: (query: FilterCompanyImportRecordsQuery) => ['companies', query.companyId, 'records', query],
  BENCHMARK: (query: SeniorityBenchmarksQuery) => ['benchmark', query],
  PUBLIC_BENCHMARK: ['publicBenchmark'],
  SUBSCRIPTION: (companyId?: string) => ['companies', companyId, 'subscription'],
  INTERCOM_SETTINGS: () => ['intercomSettings'],
};

export const REDIRECT_URL_PARAMETER = 'next';

// Authentication
export function useFetchSelf(
  options: UseQueryOptions<User, ApiError, User, string[]> = {}
): UseQueryResult<User, ApiError> {
  return useQuery(QueryKey.SELF, () => fetchSelf(), options);
}

export function useSelf(options: UseQueryOptions<User, ApiError, User, string[]> = {}): User | null {
  const query = useFetchSelf(options);
  return query.data || null;
}

export function useHasRoles(requiredRoleNames: string[]) {
  const { roles, isLoading } = useSelfRoles();
  const roleNames = roles?.map(({ name }) => name);
  return { hasRoles: requiredRoleNames.every((name) => roleNames?.includes(name)), isLoading };
}

export function useSelfRoles(options: UseQueryOptions<UserRole[], ApiError, UserRole[], string[]> = {}) {
  const query = useQuery(QueryKey.SELF_ROLES, () => fetchSelfRoles(), options);
  return useQueryWrapper(query, {
    roles: query.data,
  });
}

export function useRegistering(invitationDetails?: InvitationDetails) {
  const setAuthenticationState = useSetAuthenticationState();
  const queryClient = useQueryClient();
  const mutation = useMutation<User, ApiError, RegisterCommand, string[]>({
    mutationFn: async (user: RegisterCommand) => {
      let result = await register(user);
      if (invitationDetails) {
        const { companyId, token } = invitationDetails;
        result = await acceptInvitation(companyId, { token });
      }

      return result;
    },
    mutationKey: ['register'],
    onSuccess(user) {
      queryClient.setQueryData(QueryKey.SELF, user);
      queryClient.invalidateQueries({ queryKey: QueryKey.ONBOARDING() });
      trackUserSignup(user);
    },
  });
  return mutationWrapper(mutation, {
    async register(user: RegisterCommand) {
      const result = await mutation.mutateAsync(user);
      setAuthenticationState(AuthenticationState.AUTHENTICATED);
      return result;
    },
    user: mutation.data,
  });
}

export function useOnboardingStep(
  companyId?: string,
  options: UseQueryOptions<OnboardingState, ApiError, OnboardingState, (string | undefined)[]> = {}
) {
  const query = useQuery(QueryKey.ONBOARDING(companyId), () => fetchOnboardingState(companyId), {
    ...options,
  });
  return useQueryWrapper(query, { currentStep: query.data?.currentStep });
}

export function usePasswordResetRequest() {
  const mutation = useMutation<void, ApiError, RequestPasswordResetCommand, unknown>(
    (resetRequest: RequestPasswordResetCommand) => requestPasswordReset(resetRequest),
    {
      mutationKey: ['request-reset-password'],
    }
  );
  return mutationWrapper(mutation, {
    requestPasswordReset: async (resetRequest: RequestPasswordResetCommand) => await mutation.mutateAsync(resetRequest),
  });
}

export function useInviteUser(companyId: string) {
  const mutation = useMutation<Invitation, ApiError, InviteUserCommand, unknown>(
    (inviteCommand: InviteUserCommand) => inviteUser(companyId, inviteCommand),
    {
      mutationKey: ['invite-user'],
    }
  );
  return mutationWrapper(mutation, {
    inviteUser: async (inviteCommand: InviteUserCommand) => await mutation.mutateAsync(inviteCommand),
  });
}

export function useInvitation(
  token?: string,
  options: UseQueryOptions<Invitation | null, ApiError, Invitation, (string | undefined)[]> = {}
) {
  const query = useQuery(QueryKey.INVITATION(token), () => fetchInvitation({ token: token! }), {
    ...options,
    enabled: typeof options.enabled === 'undefined' ? !!token : options.enabled && !!token,
  });
  return useQueryWrapper(query, { invitation: query.data });
}

export function useAcceptInvitation() {
  const queryClient = useQueryClient();
  const mutation = useMutation<User, ApiError, InvitationDetails, unknown>(
    ({ companyId, token }: InvitationDetails) => acceptInvitation(companyId, { token }),
    {
      mutationKey: ['accept-invitation'],
      async onSuccess() {
        queryClient.resetQueries({ queryKey: QueryKey.ONBOARDING() });
        queryClient.invalidateQueries({ queryKey: QueryKey.USER_COMPANIES });
      },
    }
  );
  return mutationWrapper(mutation, {
    acceptInvitation: async (command: InvitationDetails) => await mutation.mutateAsync(command),
  });
}

export interface InvitationDetails {
  companyId: string;
  token: string;
}

export function usePasswordReset() {
  const mutation = useMutation<void, ApiError, ResetPasswordCommand, unknown>(
    (resetCommand: ResetPasswordCommand) => resetPassword(resetCommand),
    {
      mutationKey: ['reset-password'],
    }
  );
  return mutationWrapper(mutation, {
    resetPassword: async (resetCommand: ResetPasswordCommand) => await mutation.mutateAsync(resetCommand),
  });
}

export function useLogin() {
  const setAuthenticationState = useSetAuthenticationState();
  const queryClient = useQueryClient();
  const mutation = useMutation<User, ApiError, LoginCommand, unknown>((user: LoginCommand) => login(user), {
    mutationKey: ['login'],
    async onSuccess(user) {
      await Promise.all([
        queryClient.resetQueries({ queryKey: QueryKey.ONBOARDING() }),
        queryClient.invalidateQueries({ queryKey: QueryKey.USER_COMPANIES }),
      ]);
      queryClient.setQueryData(QueryKey.SELF, user);
      mixpanel.identify(user.id);
    },
  });
  return mutationWrapper(mutation, {
    async login(user: LoginCommand) {
      const result = await mutation.mutateAsync(user);
      setAuthenticationState(AuthenticationState.AUTHENTICATED);
      return result;
    },
    user: mutation.data,
  });
}

export function useLogout(): () => Promise<void> {
  const queryClient = useQueryClient();
  const setAuthenticationState = useSetAuthenticationState();
  return async () => {
    await logout();
    setAuthenticationState(AuthenticationState.UNAUTHENTICATED);

    queryClient.resetQueries();
  };
}

export function useSaveCompany() {
  const queryClient = useQueryClient();
  const mutation = useMutation<Company, ApiError, SaveCompanyCommand, unknown>(
    (company: SaveCompanyCommand) => saveCompany(company),
    {
      mutationKey: ['saveCompany'],
      async onSuccess(savedCompany) {
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: QueryKey.USER_COMPANIES }),
          queryClient.invalidateQueries({ queryKey: QueryKey.SELF }),
          queryClient.invalidateQueries({ queryKey: QueryKey.ONBOARDING() }),
        ]);
      },
    }
  );
  return mutationWrapper(mutation, {
    saveCompany: async (company: SaveCompanyCommand) => await mutation.mutateAsync(company),

    company: mutation.data,
  });
}

export function useEditCompany() {
  const queryClient = useQueryClient();
  const mutation = useMutation<Company, ApiError, EditCompanyCommand, unknown>(
    (command: EditCompanyCommand) => editCompany(command),
    {
      mutationKey: ['editCompany'],
      async onSuccess(savedCompany) {
        queryClient.invalidateQueries({ queryKey: QueryKey.USER_COMPANIES });
        queryClient.invalidateQueries({ queryKey: ['companies', savedCompany.id] });
      },
    }
  );
  return mutationWrapper(mutation, {
    editCompany: async (company: EditCompanyCommand) => await mutation.mutateAsync(company),
  });
}

export function useRemoveCompanyUser(companyId: string) {
  const queryClient = useQueryClient();
  const mutation = useMutation<User, ApiError, string, unknown>(
    (userId: string) => removeUserFromCompany(companyId, userId),
    {
      mutationKey: ['removeCompanyUser'],
      async onSuccess() {
        queryClient.invalidateQueries({ queryKey: ['companies', companyId] });
      },
    }
  );
  return mutationWrapper(mutation, {
    removeCompanyUser: async (userId: string) => await mutation.mutateAsync(userId),
  });
}

export function useEmplyomentChangeSuggestions(companyId: string) {
  const queryClient = useQueryClient();
  const mutation = useMutation<Employment, ApiError, SuggestEmploymentChangesCommand, unknown>(
    (command: SuggestEmploymentChangesCommand) => suggestEmploymentChanges(companyId, command),
    {
      mutationKey: ['suggestEmploymentChanges'],
      async onSuccess(employment) {
        await queryClient.invalidateQueries({ queryKey: ['companies', employment.companyId, 'employments'] });
        await queryClient.invalidateQueries({ queryKey: ['companies', employment.companyId, 'stats'] });
      },
    }
  );
  return mutationWrapper(mutation, {
    suggestEmploymentChanges: async (command: SuggestEmploymentChangesCommand) => await mutation.mutateAsync(command),
  });
}

export function useUserCompanies(options: UseQueryOptions<Company[], ApiError, Company[], string[]> = {}) {
  const query = useQuery(QueryKey.USER_COMPANIES, () => fetchUserCompanies(), {
    ...options,
  });
  return useQueryWrapper(query, { companies: query.data });
}

export function useCompanyUsers(
  companyId: string,
  options: UseQueryOptions<User[], ApiError, User[], (string | undefined)[]> = {}
) {
  const query = useQuery(QueryKey.COMPANY_USERS(companyId), () => fetchCompanyUsers(companyId), {
    ...options,
  });
  return useQueryWrapper(query, { companyUsers: query.data });
}

export function useRoles(options: UseQueryOptions<Role[], ApiError, Role[], string[]> = {}) {
  const query = useQuery(QueryKey.ROLES, () => fetchRoles(), {
    ...options,
  });
  return useQueryWrapper(query, { roles: query.data });
}

export function useCompanyStats(
  companyId: string,
  options: UseQueryOptions<CompanyStats, ApiError, CompanyStats, (string | undefined)[]> = {}
) {
  const query = useQuery(QueryKey.COMPANY_STATS(companyId), () => fetchCompanyStats(companyId), {
    ...options,
  });
  return useQueryWrapper(query, { companyStats: query.data });
}

export function useAuthenticationStatus(
  options: UseQueryOptions<User, ApiError, User, string[]> = {}
): AuthenticationState {
  const { data, error } = useFetchSelf({ retry: false, ...options });
  if (data) {
    return AuthenticationState.AUTHENTICATED;
  }

  if (error && error.status === 401) {
    return AuthenticationState.UNAUTHENTICATED;
  }

  return AuthenticationState.UNKNOWN;
}

// Signup + Benchmarking filters
export function useLocations(
  locationsQuery: LocationsQuery,
  options: UseQueryOptions<Location[], ApiError, Location[], (string | LocationsQuery)[]> = {}
) {
  const query = useQuery(QueryKey.LOCATIONS(locationsQuery), () => fetchLocations(locationsQuery), options);
  return useQueryWrapper(query, {
    locations: query.data,
  });
}
export function useHeadcountRanges(
  options: UseQueryOptions<HeadcountRange[], ApiError, HeadcountRange[], string[]> = {}
) {
  const query = useQuery(QueryKey.HEADCOUNT_RANGES, () => fetchHeadcountRanges(), options);
  return useQueryWrapper(query, {
    headcountRanges: query.data,
  });
}

export function useIndustries(options: UseQueryOptions<Industry[], ApiError, Industry[], string[]> = {}) {
  const query = useQuery(QueryKey.INDUSTRIES, () => fetchIndustries(), options);
  return useQueryWrapper(query, {
    industries: query.data,
  });
}

// Benchmarking filters
export function usePositions(options: UseQueryOptions<Position[], ApiError, Position[], string[]> = {}) {
  const query = useQuery(QueryKey.POSITIONS, () => fetchPositions(), options);
  return useQueryWrapper(query, {
    positions: query.data,
  });
}

export function usePositionSeniorities(
  positionId?: string,
  queryParams?: PositionSenioritiesQuery,
  queryOptions: UseQueryOptions<Seniority[], ApiError, Seniority[], string[]> = {}
) {
  const query = useQuery(
    QueryKey.SENIORITIES(positionId, queryParams),
    () => fetchPositionSeniorities(positionId!, queryParams),
    {
      enabled: typeof queryOptions.enabled === 'undefined' ? !!positionId : queryOptions.enabled && !!positionId,
    }
  );
  return useQueryWrapper(query, {
    seniorities: query.data,
  });
}

// Benchmarking
export function useSeniorityBenchmarks(
  benchmarkQuery: SeniorityBenchmarksQuery,
  options: UseQueryOptions<SeniorityBenchmark[], ApiError, SeniorityBenchmark[], any[]> = {}
) {
  const query = useQuery(QueryKey.BENCHMARK(benchmarkQuery), () => fetchSeniorityBenchmarks(benchmarkQuery), options);
  return useQueryWrapper(query, {
    benchmarks: query.data,
  });
}

export function usePublicBenchmarks(
  options: UseQueryOptions<SeniorityBenchmark[], ApiError, SeniorityBenchmark[], string[]> = {}
) {
  const query = useQuery(QueryKey.PUBLIC_BENCHMARK, () => fetchPublicSeniorityBenchmarks(), options);
  return useQueryWrapper(query, {
    benchmarks: query.data,
  });
}

export function useDefaultFilters(options: UseQueryOptions<DefaultFilters, ApiError, DefaultFilters, string[]> = {}) {
  const query = useQuery(QueryKey.DEFAULT_FILTERS, () => fetchDefaultFilters(), options);
  return useQueryWrapper(query, {
    defaultFilters: query.data,
  });
}

// Employments imports
export function useLatestEmploymentsImport(
  companyId?: string,
  options: UseQueryOptions<EmploymentsImport | null, ApiError, EmploymentsImport, (string | undefined)[]> = {}
) {
  const query = useQuery(
    QueryKey.LATEST_EMPLOYMENTS_IMPORT(companyId),
    () => fetchLatestEmploymentsImport(companyId!),
    {
      ...options,
      enabled: typeof options.enabled === 'undefined' ? !!companyId : options.enabled && !!companyId,
    }
  );
  return useQueryWrapper(query, { latestEmploymentsImport: query.data });
}

export function useEmploymentsImport(
  employmentsImportId?: string,
  options: UseQueryOptions<EmploymentsImport | null, ApiError, EmploymentsImport, string[]> = {}
) {
  const query = useQuery(
    QueryKey.EMPLOYMENTS_IMPORT(employmentsImportId!),
    () => fetchEmploymentsImport(employmentsImportId!),
    {
      ...options,
      enabled:
        typeof options.enabled === 'undefined' ? !!employmentsImportId : options.enabled && !!employmentsImportId,
    }
  );
  return useQueryWrapper(query, { employmentsImport: query.data });
}

// HRIS integration
export function useKomboConnectionLinkCreation() {
  const mutation = useMutation<KomboConnectionLink, ApiError, string, string[]>(
    (companyId: string) => createKomboConnectionLink(companyId),
    {
      mutationKey: ['createKomboConnectionLink'],
    }
  );
  return mutationWrapper(mutation, {
    createConnectionLink: async (companyId: string) => await mutation.mutateAsync(companyId),
  });
}

export function useKomboIntegrationCreation() {
  const queryClient = useQueryClient();
  const mutation = useMutation<HRISIntegration, ApiError, CreateKomboIntegrationMutationCommand, string[]>(
    (command: CreateKomboIntegrationMutationCommand) =>
      createKomboIntegration(command.companyId, { token: command.token }),
    {
      mutationKey: ['createIntegration'],
      onSuccess(integration: HRISIntegration) {
        queryClient.invalidateQueries({ queryKey: QueryKey.LATEST_EMPLOYMENTS_IMPORT(integration.companyId) });
        queryClient.invalidateQueries({ queryKey: QueryKey.ONBOARDING() });
      },
    }
  );
  return mutationWrapper(mutation, {
    createKomboIntegration: async (companyId: string, token: string) =>
      await mutation.mutateAsync({
        companyId,
        token,
      }),
  });
}

interface CreateKomboIntegrationMutationCommand {
  companyId: string;
  token: string;
}

// import records
export function useCompanyImportRecords(
  filterQuery: FilterCompanyImportRecordsQuery,
  options?: UseQueryOptions<
    PaginationResult<ImportRecordWithLabels>,
    ApiError,
    PaginationResult<ImportRecordWithLabels>,
    (string | FilterCompanyImportRecordsQuery)[]
  >
) {
  const query = useQuery(QueryKey.COMPANY_IMPORT_RECORDS(filterQuery), () => fetchCompanyImportRecords(filterQuery), {
    ...options,
  });
  return useQueryWrapper(query, { importRecords: query.data?.items, totalCount: query.data?.totalCount });
}

export function useConfirmImportRecord(companyId: string) {
  const queryClient = useQueryClient();
  const mutation = useMutation<ImportRecordWithLabels, ApiError, ConfirmImportRecordCommand, string[]>(
    (confirmCommand: ConfirmImportRecordCommand) => confirmImportRecord(companyId, confirmCommand),
    {
      mutationKey: ['confirmImportRecord'],
      async onSuccess({ companyId }) {
        await queryClient.invalidateQueries({ queryKey: ['companies', companyId, 'records'] });
      },
    }
  );
  return mutationWrapper(mutation, {
    confirmImportRecord: async (command: ConfirmImportRecordCommand) => await mutation.mutateAsync(command),
  });
}

// Employments
export function useCompanyEmployments(
  filter: FilterCompanyEmploymentsQuery,
  options?: UseQueryOptions<
    PaginationResult<Employment>,
    ApiError,
    PaginationResult<Employment>,
    (string | FilterCompanyEmploymentsQuery)[]
  >
) {
  const query = useQuery(QueryKey.COMPANY_EMPLOYMENTS(filter), () => fetchCompanyEmployments(filter), {
    ...options,
  });
  return useQueryWrapper(query, { employments: query.data?.items, totalCount: query.data?.totalCount });
}

// backoffice
export function useImportRecords(
  filterQuery: FilterImportRecordsQuery,
  options?: UseQueryOptions<
    PaginationResult<ImportRecordWithLabels>,
    ApiError,
    PaginationResult<ImportRecordWithLabels>,
    (string | FilterEmploymentsImportsQuery)[]
  >
) {
  const query = useQuery(QueryKey.IMPORT_RECORDS(filterQuery), () => fetchImportRecords(filterQuery), {
    ...options,
  });
  return useQueryWrapper(query, { importRecords: query.data?.items, totalCount: query.data?.totalCount });
}

export function useLabeling({
  employmentsImportId,
  importRecordId,
  onAllRecordsCompleted,
}: {
  employmentsImportId?: string;
  importRecordId?: string;
  onAllRecordsCompleted: () => void;
}) {
  const queryClient = useQueryClient();
  const {
    importRecord,
    refetch: fetchNextRecord,
    isLoading: isFetching,
  } = useImportRecordToLabel(
    { employmentsImportId, importRecordId },
    {
      onSuccess: (data?: ImportRecord) => {
        // when the user is labeling a single record (thus fetching a single record by id) the query will always return data
        if (!data) {
          // when no more records are returned the labeler has labeled all the records
          onAllRecordsCompleted();
        }
      },
    }
  );

  const isLabelingSingleRecord = !!importRecordId;
  const onSuccess = async () => {
    queryClient.invalidateQueries({ queryKey: ['employmentsImports'] });
    if (!isLabelingSingleRecord) {
      await fetchNextRecord();
    } else {
      onAllRecordsCompleted();
    }
  };
  const { labelImportRecord, isLoading: isLabeling } = useLabelImportRecord({ onSuccess });
  const { skipImportRecord, isLoading: isSkipping } = useSkipImportRecord({ onSuccess });
  const { terminateImportRecord, isLoading: isTerminating } = useTerminateImportRecord({ onSuccess });
  const { requestRecordVerification, isLoading: isRequestingConfirmation } = useConfirmationRequest({ onSuccess });

  return {
    importRecord,
    labelImportRecord,
    skipImportRecord,
    terminateImportRecord,
    requestRecordVerification,
    isFetching,
    isLabeling: isLabeling || isSkipping || isTerminating || isRequestingConfirmation,
  };
}

function useImportRecordToLabel(
  {
    employmentsImportId,
    importRecordId,
  }: {
    employmentsImportId?: string;
    importRecordId?: string;
  },
  options: Omit<
    UseQueryOptions<
      ImportRecordWithLabels | undefined,
      ApiError,
      ImportRecordWithLabels | undefined,
      (string | undefined)[]
    >,
    'cacheTime'
  > = {}
) {
  const query = useQuery(
    QueryKey.EMPLOYMENTS_IMPORT_RECORD_TO_LABEL(employmentsImportId, importRecordId),
    () => fetchImportRecordToLabel({ employmentsImportId: employmentsImportId!, importRecordId }),
    {
      ...options,
      enabled:
        typeof options.enabled === 'undefined' ? !!employmentsImportId : options.enabled && !!employmentsImportId,
      // force 0 cache time to avoid fetching already labeled records for labeling
      cacheTime: 0,
    }
  );
  return useQueryWrapper(query, { importRecord: query.data });
}

function useLabelImportRecord(options: UseMutationOptions<ImportRecord, ApiError, LabelImportRecordCommand, unknown>) {
  const mutation = useMutation<ImportRecord, ApiError, LabelImportRecordCommand, unknown>(
    (command: LabelImportRecordCommand) => labelImportRecord(command),
    {
      ...options,
      mutationKey: ['labelImportRecord'],
    }
  );
  return mutationWrapper(mutation, {
    labelImportRecord: async (command: LabelImportRecordCommand) => await mutation.mutateAsync(command),
  });
}

function useSkipImportRecord(options: UseMutationOptions<ImportRecord, ApiError, string, unknown>) {
  const mutation = useMutation<ImportRecord, ApiError, string, unknown>(
    (importRecordId: string) => skipImportRecord(importRecordId),
    {
      ...options,
      mutationKey: ['skipImportRecord'],
    }
  );
  return mutationWrapper(mutation, {
    skipImportRecord: async (importRecordId: string) => await mutation.mutateAsync(importRecordId),
  });
}

function useTerminateImportRecord(options: UseMutationOptions<ImportRecord, ApiError, string, unknown>) {
  const mutation = useMutation<ImportRecord, ApiError, string, unknown>(
    (importRecordId: string) => terminateImportRecord(importRecordId),
    {
      ...options,
      mutationKey: ['terminateImportRecord'],
    }
  );
  return mutationWrapper(mutation, {
    terminateImportRecord: async (importRecordId: string) => await mutation.mutateAsync(importRecordId),
  });
}

function useConfirmationRequest(
  options: UseMutationOptions<ImportRecordWithLabels, ApiError, RequestRecordVerificationCommand, unknown>
) {
  const mutation = useMutation<ImportRecordWithLabels, ApiError, RequestRecordVerificationCommand, unknown>(
    (command: RequestRecordVerificationCommand) => requestRecordVerification(command),
    {
      ...options,
      mutationKey: ['requestRecordVerification'],
    }
  );
  return mutationWrapper(mutation, {
    requestRecordVerification: async (command: RequestRecordVerificationCommand) => await mutation.mutateAsync(command),
  });
}

export function useAssignEmploymentsImport() {
  const queryClient = useQueryClient();
  const mutation = useMutation<EmploymentsImport, ApiError, PatchEmploymentsImportAssigneeCommand, unknown>(
    (command: PatchEmploymentsImportAssigneeCommand) => patchEmploymentsImportAssignee(command),
    {
      mutationKey: ['assignEmploymentsImport'],
      onSuccess(updatedImport) {
        queryClient.setQueryData<EmploymentsImport>(QueryKey.EMPLOYMENTS_IMPORT(updatedImport.id), updatedImport);
        queryClient.invalidateQueries({ queryKey: ['employmentsImports', 'filter'] });
      },
    }
  );
  return mutation;
  // React Table goes crazy when reactive on mutationWrapper's overrides
  //return mutationWrapper(mutation, {
  //  assignEmploymentsImport: async (command: PatchEmploymentsImportAssigneeCommand) =>
  //    await mutation.mutateAsync(command),
  //});
}

export function useEmploymentsImportActions() {
  const { mutateAsync: updateStatus, isLoading } = useEmploymentsImportStatusUpdate();
  return {
    accept: async (employmentsImportId: string) =>
      await updateStatus({ employmentsImportId, status: EmploymentsImportStatus.ACCEPTED }),
    reject: async (employmentsImportId: string) =>
      await updateStatus({ employmentsImportId, status: EmploymentsImportStatus.REJECTED }),
    isLoading,
  };
}

function useEmploymentsImportStatusUpdate(
  options?: UseMutationOptions<EmploymentsImport, ApiError, EmploymentsImportStatusUpdateCommand, unknown>
) {
  const queryClient = useQueryClient();

  const mutation = useMutation<EmploymentsImport, ApiError, EmploymentsImportStatusUpdateCommand, unknown>(
    (command: EmploymentsImportStatusUpdateCommand) => updateEmploymentsImportStatus(command),
    {
      mutationKey: ['updateEmploymentsImportStatus'],
      onSuccess: (updatedImport: EmploymentsImport) => {
        queryClient.setQueryData<EmploymentsImport>(QueryKey.EMPLOYMENTS_IMPORT(updatedImport.id), updatedImport);
        queryClient.invalidateQueries({ queryKey: ['employmentsImports', 'filter'] });
      },
      ...options,
    }
  );
  return mutation;
}

export function useEmploymentsImportAssignees(
  options: UseQueryOptions<EmploymentsImportAssignee[], ApiError, EmploymentsImportAssignee[], string[]> = {}
) {
  const query = useQuery(QueryKey.EMPLOYMENTS_IMPORT_ASSIGNEES, () => fetchEmploymentsImportAssignees(), {
    ...options,
  });
  return useQueryWrapper(query, { assignees: query.data });
}

export function useEmploymentsImportListItems(
  filter: FilterEmploymentsImportsQuery,
  options: UseQueryOptions<
    PaginationResult<EmploymentsImportListItem>,
    ApiError,
    PaginationResult<EmploymentsImportListItem>,
    (string | FilterEmploymentsImportsQuery)[]
  > = {}
) {
  const query = useQuery(QueryKey.EMPLOYMENTS_IMPORTS(filter), () => fetchEmploymentsImports(filter), options);
  return useQueryWrapper(query, {
    employmentsImports: query.data?.items || [],
    totalCount: query.data?.totalCount || 0,
  });
}

export function useSubscription(
  companyId?: string,
  options: UseQueryOptions<Subscription | null, ApiError, Subscription | null, (string | undefined)[]> = {}
) {
  const query = useQuery(QueryKey.SUBSCRIPTION(companyId), () => fetchSubscription(companyId!), {
    ...options,
    enabled: typeof options.enabled === 'undefined' ? !!companyId : options.enabled && !!companyId,
  });

  return useQueryWrapper(query, {
    subscription: query.data,
  });
}

export function useIntercomSettings(
  options: UseQueryOptions<IntercomSettings | null, ApiError, IntercomSettings | null, string[]> = {}
) {
  const query = useQuery(QueryKey.INTERCOM_SETTINGS(), () => fetchIntercomSettings(), options);

  return useQueryWrapper(query, {
    intercomSettings: query.data,
  });
}

// Redirects
export function useUrlParameterAwareAppRedirect() {
  const navigate = useNavigate();
  const navigateToDefaultRoute = useNavigateToDefaultRoute();
  const redirectUrl = useRedirectUrlParameter();
  return useCallback(() => {
    if (redirectUrl) {
      navigate(redirectUrl, { replace: true });
    } else {
      navigateToDefaultRoute();
    }
  }, [navigate, navigateToDefaultRoute, redirectUrl]);
}

export function useNavigateToDefaultRoute() {
  const navigate = useNavigate();

  return useCallback(
    (options?: NavigateOptions) => {
      navigate('/market', options);
    },
    [navigate]
  );
}

export function useRedirectUrlParameter(): string | null {
  const [params] = useSearchParams();
  const redirectUrl = params.get(REDIRECT_URL_PARAMETER);
  if (!redirectUrl || isUrlAbsolute(redirectUrl)) {
    return null;
  }
  return redirectUrl;
}

// Utilities
function mutationWrapper<A, B, C, D, T>(
  { isIdle, isLoading, isSuccess, isError, error, status }: UseMutationResult<A, B, C, D>,
  overrides: T
): {
  error: null | B;
  isIdle: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
} & T {
  return {
    error,
    isIdle,
    isLoading,
    isSuccess,
    isError,
    ...overrides,
  };
}

function useQueryWrapper<QueryT, ErrorT, OverrideT>(
  queryResult: UseQueryResult<QueryT, ErrorT>,
  overrides: OverrideT
): {
  error: null | ErrorT;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  isFetched: boolean;
  isPreviousData: boolean;
  isFetching: boolean;
  isInitialLoading: boolean;
  refetch: (options?: RefetchOptions | undefined) => Promise<UseQueryResult<QueryT, ErrorT>>;
} & OverrideT {
  return useMemo(() => {
    const { isLoading, isSuccess, isError, error, refetch, isFetched, isPreviousData, isFetching, isInitialLoading } =
      queryResult;
    return {
      error,
      isLoading,
      isSuccess,
      isError,
      refetch,
      isFetched,
      isPreviousData,
      isFetching,
      isInitialLoading,
      ...overrides,
    };
  }, [queryResult, overrides]);
}

function isUrlAbsolute(url: string): boolean {
  if (url.indexOf('//') === 0) {
    return true;
  } // URL is protocol-relative (= absolute)
  if (url.indexOf('://') === -1) {
    return false;
  } // URL has no protocol (= relative)
  if (url.indexOf('.') === -1) {
    return false;
  } // URL does not contain a dot, i.e. no TLD (= relative, possibly REST)
  if (url.indexOf('/') === -1) {
    return false;
  } // URL does not contain a single slash (= relative)
  if (url.indexOf(':') > url.indexOf('/')) {
    return false;
  } // The first colon comes after the first slash (= relative)
  if (url.indexOf('://') < url.indexOf('.')) {
    return true;
  } // Protocol is defined before first dot (= absolute)
  return false; // Anything else must be relative
}
