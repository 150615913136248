import { FC, ReactNode } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { useIntercomSettings } from '../api/hooks';

const INTERCOM_APP_ID = 't1civ5yf';

export const Intercom: FC<{ children: ReactNode }> = ({ children }) => {
  const { intercomSettings } = useIntercomSettings();

  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      autoBoot={true}
      autoBootProps={{
        customAttributes: { app: 'Compensation.app' },
        userId: intercomSettings?.userId,
        userHash: intercomSettings?.userHash,
      }}
    >
      {children}
    </IntercomProvider>
  );
};
