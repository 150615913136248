import { Footer } from './Footer';
import { NavBar } from './NavBar';

export const PublicLayout: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <div className="flex flex-col h-full">
      <NavBar />
      <div className="flex flex-col justify-between h-full">
        {children}
        <Footer />
      </div>
    </div>
  );
};
