import { Outlet } from 'react-router-dom';
import { Authenticated } from './Authenticated';

export const AuthenticatedRoute = () => {
  return (
    <Authenticated>
      <Outlet />
    </Authenticated>
  );
};
