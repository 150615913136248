import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Percentile } from '../../../api/types';
import { formatAmount } from '../../util/formatUtils';

export const PercentilesTable: React.FunctionComponent<{
  percentiles: Percentile[] | null;
  isSelected: boolean;
  targetPercentile: { percentile: number };
}> = ({ percentiles, isSelected, targetPercentile }) => {
  const orderedPercentiles = useMemo(() => getOrderedPercentiles(percentiles), [percentiles]);

  const isTargetPercentile = (p: PercentileRecord) => isSelected && targetPercentile.percentile === p.value;

  if (orderedPercentiles === null) {
    return <p className="text-center w-full">We're collecting data for this one. Stay tuned!</p>;
  }

  return (
    <table className="table-auto w-full">
      <thead className="border-b border-black pb-2">
        <tr>
          {orderedPercentiles.map((p) => (
            <th
              className={classNames('font-normal text-left pb-1 pr-2', {
                'font-bold': p.score && isTargetPercentile(p),
              })}
              key={p.value}
            >
              {p.value}th
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {orderedPercentiles.map((p) => (
            <td key={p.value.toString() + (p.score || '-')}>
              <p
                className={classNames('font-medium pt-1 pr-2', {
                  'font-bold': p.score && isTargetPercentile(p),
                })}
              >
                {`${p.score != null ? `${formatAmount(p.score)}€` : '-'}`}
              </p>
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

interface PercentileRecord {
  score: number | null;
  value: number;
}

function getOrderedPercentiles(percentiles: Percentile[] | null): PercentileRecord[] | null {
  if (percentiles == null) {
    return null;
  }
  const percentilesCopy = [...percentiles];
  percentilesCopy.sort((p1, p2) => p1.value - p2.value);
  return percentilesCopy;
}
