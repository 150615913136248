import { Field, FieldProps } from 'formik';
import { useMemo, useState } from 'react';
import { useLocations } from '../../../api/hooks';
import { Location, LocationType } from '../../../api/types';
import { Combobox, ComboboxProps, GroupedComboboxOptions } from '../../../design/combobox/Combobox';
import { locationToComboboxOption } from '../../../design/combobox/optionTransformers';
import { FieldErrorMessage, FieldSize } from '../../../design/field/Field';
import { groupBy, useDebouncedState } from '../util';

type LocationProps<IsMulti extends boolean> = {
  locationType: LocationType;
  withBenchmarksOnly?: boolean;
  groupByCountry?: boolean;
} & Omit<ComboboxProps<IsMulti>, 'options'>;

export function LocationsFormikField<IsMulti extends boolean = false>({
  name,

  ...locationsComboboxProps
}: { name: string; label: string } & LocationProps<IsMulti>) {
  return (
    <Field name={name}>
      {({ field: { value, onBlur }, form: { setFieldValue }, meta }: FieldProps) => (
        <div>
          <LocationsCombobox
            id={name}
            value={value}
            onChange={(comboboxOption) => {
              setFieldValue(name, comboboxOption);
            }}
            onBlur={onBlur}
            {...locationsComboboxProps}
          />
          <FieldErrorMessage
            errorMessage={meta.touched && meta.error}
            size={(locationsComboboxProps.size || FieldSize.MEDIUM) as FieldSize}
          />
        </div>
      )}
    </Field>
  );
}

export default function LocationsCombobox<IsMulti extends boolean = false>({
  locationType,
  withBenchmarksOnly,
  groupByCountry = false,

  ...comboboxProps
}: {
  locationType: LocationType;
  withBenchmarksOnly?: boolean;
  groupByCountry?: boolean;
} & Omit<ComboboxProps<IsMulti>, 'options'>) {
  const [query, setQuery] = useState('');

  const locationNameFilter = useDebouncedState(query);
  const { locations, isLoading } = useLocations({ locationType, withBenchmarksOnly, name: locationNameFilter });
  const options = useMemo(
    () =>
      groupByCountry ? groupByCountries(locations) : (locations || []).map((loc) => locationToComboboxOption(loc)),
    [locations, groupByCountry]
  );

  return (
    <Combobox
      {...comboboxProps}
      options={options}
      filterOption={() => true}
      isLoading={isLoading}
      onInputChange={setQuery}
      isSearchable={true}
    />
  );
}

function groupByCountries(locations: Location[] | undefined): GroupedComboboxOptions[] {
  if (!locations) {
    return [];
  }
  const matchingCountries: Location[] = locations.filter((loc) => loc.type === LocationType.COUNTRY);
  const parentCountries: Location[] = locations
    .filter((loc) => loc.parentLocation?.type === LocationType.COUNTRY)
    .map((loc) => loc.parentLocation!);

  const groupedOptions: Map<string, GroupedComboboxOptions> = groupBy(
    [...matchingCountries, ...parentCountries],
    (loc) => loc.id,
    (loc) => ({ label: loc.name, value: locationToComboboxOption(loc, false), options: [] })
  );
  locations
    .filter((loc) => loc.type === LocationType.CITY && loc.parentLocation)
    .forEach((loc) => groupedOptions.get(loc.parentLocation!.id)?.options.push(locationToComboboxOption(loc, false)));
  return Array.from(groupedOptions.values());
}
