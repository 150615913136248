import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

export const NavigationLink = ({
  className,
  to,
  children,
  disabled,
}: {
  className?: string;
  to: string;
  children?: React.ReactNode;
  disabled?: boolean;
}) => {
  const [hovering, setHovering] = useState(false);
  const getClassNames = ({ isActive, isDisabled }: { isActive?: boolean; isDisabled?: boolean }) =>
    classNames(
      isActive ? 'bg-brandGreen-light text-slate-900' : 'text-gray-600 ',
      isDisabled ? 'cursor-not-allowed' : 'hover:bg-brandGreen-light hover:text-slate-900',
      'transition-color ease-in-out duration-200 group flex items-center py-4 px-6 text-base font-semibold rounded-md whitespace-nowrap',
      className
    );
  if (disabled) {
    const animations = {
      initial: { x: 40, opacity: 0 },
      animate: { x: 0, opacity: 1 },
      exit: { x: -40, opacity: 0 },
      transition: {
        opacity: { duration: 0.2 },
      },
    };
    return (
      <span
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onFocus={() => setHovering(true)}
        onBlur={() => setHovering(false)}
        className={getClassNames({ isDisabled: true })}
      >
        <AnimatePresence mode="popLayout">
          {hovering ? (
            <motion.span key="placeholder" {...animations}>
              Coming soon
            </motion.span>
          ) : (
            <motion.span key="content" {...animations}>
              {children}
            </motion.span>
          )}
        </AnimatePresence>
      </span>
    );
  }
  return (
    <NavLink to={to} className={({ isActive }) => getClassNames({ isActive })}>
      {children}
    </NavLink>
  );
};
