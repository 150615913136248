import { motion, useReducedMotion } from 'framer-motion';

export const ContentTransition: React.FunctionComponent<{ id: string; children: React.ReactNode }> = ({
  id,
  children,
}) => {
  const reducedMotion = useReducedMotion();
  return (
    <motion.div
      key={id}
      initial={reducedMotion ? { opacity: 0 } : { y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={reducedMotion ? { opacity: 0 } : { y: 0, opacity: 0 }}
      transition={{ y: { type: 'spring', duration: 0.4 }, staggerChildren: 2 }}
    >
      {children}
    </motion.div>
  );
};
