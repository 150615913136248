import classNames from 'classnames';
import { forwardRef, Ref } from 'react';

export enum LoaderSize {
  SMALL = 'SM',
  MEDIUM = 'MD',
  LARGE = 'LG',
}

export interface LoaderProps {
  className?: string;
  size?: LoaderSize;
}

const sizeClasses = {
  [LoaderSize.SMALL]: 'h-8 w-8',
  [LoaderSize.MEDIUM]: 'h-12 w-12',
  [LoaderSize.LARGE]: 'h-24 w-24',
};

export const Loader: React.FunctionComponent<LoaderProps> = forwardRef(
  ({ className, size = LoaderSize.MEDIUM }, ref) => (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('mx-auto stroke-gray-400', sizeClasses[size], className)}
      ref={ref as Ref<any>}
    >
      <g fill="none" fillRule="evenodd" strokeWidth="2">
        <circle cx="22" cy="22" r="1">
          <animate
            attributeName="r"
            begin="0s"
            dur="1.8s"
            values="1; 20"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="0s"
            dur="1.8s"
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="22" cy="22" r="1">
          <animate
            attributeName="r"
            begin="-0.9s"
            dur="1.8s"
            values="1; 20"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="-0.9s"
            dur="1.8s"
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </svg>
  )
);
