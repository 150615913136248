import classNames from 'classnames';

export const DetailsLayout: React.FunctionComponent<{
  children?: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div className={classNames('grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4', className)}>{children}</div>
  );
};
