import React, { ComponentType, Fragment, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AuthenticationProvider } from './authentication/Authentication';
import { AuthenticatedRoute } from './authentication/AuthenticatedRoute';
import { Homepage } from './static/homepage/Homepage';
import { NotFoundPage } from './static/NotFoundPage';
import { AppLayoutRoute } from './app/layout/AppLayoutRoute';
import { PublicLayoutRoute } from './static/layout/PublicLayoutRoute';
import { CookieConsentProvider } from './app/cookies/CookieConsent';
import { MixpanelController } from './meta/MixpanelController';
import { RequiresOnboardingCompletedRoute } from './authentication/RequiresOnboardingCompletedRoute';
import { PasswordResetRequestPage } from './authentication/passwordReset/PasswordResetRequestPage';
import { PasswordResetPage } from './authentication/passwordReset/PasswordResetPage';
import { AuthorizedRoute } from './authentication/AuthorizedRoute';
import { RoleName } from './api/types';
import { BackOfficeLayoutRoute } from './backOffice/layout/BackOfficeLayoutRoute';
import { ImportRecordLabelingPage } from './backOffice/importRecords/ImportRecordLabelingPage';
import EmploymentsImportPage from './backOffice/employmentsImportPage/EmploymentsImportPage';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastProvider } from './design/toast/ToastProvider';
import { ActiveCompanyProvider } from './authentication/ActiveCompany';
import { Intercom } from './meta/Intercom';

const PrivacyPolicyPage = lazy(() => import('./static/legal/PrivacyPolicyPage'), 'PrivacyPolicyPage');
const TermsAndConditionsPage = lazy(() => import('./static/legal/TermsAndConditionsPage'), 'TermsAndConditionsPage');
const CookiePolicyPage = lazy(() => import('./static/legal/CookiePolicyPage'), 'CookiePolicyPage');

const LoginPage = lazy(() => import('./authentication/login/Login'), 'LoginPage');
const DesignPage = lazy(() => import('./static/design/DesignPage'), 'DesignPage');
const OnboardingPage = lazy(() => import('./app/onboarding/OnboardingPage'), 'OnboardingPage');
const InvitationPage = lazy(() => import('./app/invitation/InvitationPage'), 'InvitationPage');

const MarketDataPage = lazy(() => import('./app/marketData/MarketDataPage'), 'MarketDataPage');
const ExportPage = lazy(() => import('./app/export/ExportPage'), 'ExportPage');
const OrganizationPage = lazy(() => import('./app/organization/OrganizationPage'), 'OrganizationPage');
const TeamPage = lazy(() => import('./app/team/TeamPage'), 'TeamPage');
const UpgradePage = lazy(() => import('./app/subscription/UpgradePage'), 'UpgradePage');

const EmploymentsImportsPage = lazy(
  () => import('./backOffice/employmentsImports/EmploymentsImportsPage'),
  'EmploymentsImportsPage'
);

function lazy<T extends ComponentType<any>, ExportName extends string>(
  importer: () => Promise<Record<ExportName, T>>,
  exportName: ExportName
) {
  return React.lazy(async () => {
    const module = await importer();
    return { default: module[exportName] };
  });
}

function defaultQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 60 * 1000,
        retry: false,
      },
    },
  });
}

export const CompensationApp: React.FunctionComponent<{
  queryClient?: QueryClient;
}> = ({ queryClient = defaultQueryClient() }) => {
  // TODO: add loader for top-level suspense instead of fragment

  return (
    <CookieConsentProvider>
      <MixpanelController />
      <QueryClientProvider client={queryClient}>
        <AuthenticationProvider>
          <Intercom>
            <ActiveCompanyProvider>
              <ToastProvider>
                <Suspense fallback={<Fragment />}>
                  <AppRoutes />
                </Suspense>
              </ToastProvider>
            </ActiveCompanyProvider>
          </Intercom>
        </AuthenticationProvider>
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
      </QueryClientProvider>
    </CookieConsentProvider>
  );
};

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public routes */}
      <Route element={<PublicLayoutRoute />}>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/request-password-reset" element={<PasswordResetRequestPage />} />
        <Route path="/password-reset" element={<PasswordResetPage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
        <Route path="/cookies" element={<CookiePolicyPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      </Route>

      {/* Public routes with a different layout */}
      <Route path="/onboarding" element={<OnboardingPage />} />
      <Route path="/invitation" element={<InvitationPage />} />

      {process.env.NODE_ENV === 'development' ? <Route path="/design" element={<DesignPage />} /> : ''}

      {/* Authenticated routes */}
      <Route element={<AuthenticatedRoute />}>
        <Route element={<RequiresOnboardingCompletedRoute />}>
          {/* Routes with the application layout */}
          <Route element={<AppLayoutRoute />}>
            <Route path="/market" element={<MarketDataPage />} />
            <Route path="/export" element={<ExportPage />} />
            <Route path="/organisation" element={<OrganizationPage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/upgrade" element={<UpgradePage />} />
          </Route>
        </Route>

        {/* Backoffice routes */}
        <Route path="/backoffice" element={<AuthorizedRoute requiredRole={RoleName.ALIGN_ADMIN} />}>
          <Route element={<BackOfficeLayoutRoute />}>
            <Route path="employments-imports" element={<EmploymentsImportsPage />} />
            <Route path="employments-imports/:employmentsImportId" element={<EmploymentsImportPage />} />
            <Route
              path="employments-imports/:employmentsImportId/records/labeling"
              element={<ImportRecordLabelingPage />}
            >
              <Route path=":importRecordId" element={<ImportRecordLabelingPage />} />
            </Route>
          </Route>

          {/* Routes without the application layout, but still authenticated */}
        </Route>
      </Route>

      {/* Catch-all, for 404, using public layout. */}
      <Route element={<PublicLayoutRoute />}>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};
