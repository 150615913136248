import { Logo } from '../../design/logo/Logo';

export const DesktopSidebar = ({
  navChildren,
  footerChildren,
}: {
  navChildren?: React.ReactNode;
  footerChildren?: React.ReactNode;
}) => {
  return (
    <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
      <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
        <div className="flex flex-1 flex-col overflow-y-auto pt-8 pb-4 items-center px-8">
          <div className="border-b border-neutral-300 pb-4 w-full flex justify-center">
            <Logo className="h-8 w-auto" />
          </div>
          <nav className="mt-5 flex-1 space-y-1 bg-white w-full">{navChildren}</nav>
        </div>
        <div className="flex flex-shrink-0 border-t border-gray-200 p-4">{footerChildren}</div>
      </div>
    </div>
  );
};
