import { Outlet } from 'react-router-dom';
import { RoleName } from '../api/types';
import { Authorized } from './Authorized';

export const AuthorizedRoute = ({ requiredRole }: { requiredRole: RoleName }) => {
  return (
    <Authorized requiredRole={requiredRole}>
      <Outlet />
    </Authorized>
  );
};
