import { Outlet } from 'react-router-dom';
import { RequiresOnboardingCompleted } from './RequiresOnboardingCompleted';

export const RequiresOnboardingCompletedRoute = () => {
  return (
    <RequiresOnboardingCompleted>
      <Outlet />
    </RequiresOnboardingCompleted>
  );
};
