import { Link } from 'react-router-dom';
import { Container } from '../design/container/Container';

export const NotFoundPage = () => {
  return (
    <Container>
      <h1 className="mt-32 font-semibold text-3xl mb-8">404: Page not found</h1>
      <p>
        Looks like you're lost.{' '}
        <Link to="/" className="a">
          Go home
        </Link>
      </p>
    </Container>
  );
};
