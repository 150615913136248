import { useState } from 'react';
import {
  useAssignEmploymentsImport,
  useEmploymentsImportActions,
  useEmploymentsImportAssignees,
} from '../../api/hooks';
import { EmploymentsImport, EmploymentsImportStatus } from '../../api/types';
import { Button, ButtonSize } from '../../design/button/Button';
import { formatEnum } from '../../app/util/formatUtils';
import { Combobox, ComboboxOption, ComboboxSize } from '../../design/combobox/Combobox';
import { employmentsImportAssigneeToComboboxOption } from '../../design/combobox/optionTransformers';
import { Detail } from '../../design/detail/Detail';
import { Loader, LoaderSize } from '../../design/loader/Loader';
import { DetailsLayout } from '../../app/layout/DetailsLayout';
import { Check, Close, Edit } from '../../design/icons/Icons';
import classNames from 'classnames';
import { Header, HeaderSize } from '../../design/header/Header';

export function EmploymentsImportSection({
  employmentsImport,
  isLoading,
}: {
  employmentsImport?: EmploymentsImport;
  isLoading: boolean;
}) {
  return (
    <div>
      <Header size={HeaderSize.LARGE}>Employments Import</Header>
      {isLoading || !employmentsImport ? (
        <Loader size={LoaderSize.LARGE} />
      ) : (
        <>
          <DetailsLayout>
            <Detail title="Id" value={employmentsImport.id} />
            <Detail title="Created At" value={employmentsImport.creationTime?.toLocaleString('et')} />
            <Detail title="Type" value={employmentsImport.type} />
            <EditableAssigneeDetail employmentsImport={employmentsImport} />
            <Detail title="Status" value={formatEnum(employmentsImport.status)} />
          </DetailsLayout>

          {employmentsImport.status === EmploymentsImportStatus.IN_REVIEW && (
            <EmploymentsImportActions employmentsImport={employmentsImport} />
          )}
        </>
      )}
    </div>
  );
}

function EmploymentsImportActions({ employmentsImport }: { employmentsImport: EmploymentsImport }) {
  const { accept, reject, isLoading: isHandlingImport } = useEmploymentsImportActions();

  return (
    <div className="flex gap-4 mt-6">
      <Button
        id="acceptEmploymentsImport"
        disabled={isHandlingImport}
        onClick={() => {
          accept(employmentsImport.id);
        }}
        size={ButtonSize.MEDIUM}
        primary
      >
        Accept
      </Button>
      <Button
        id="rejectEmploymentsImport"
        disabled={isHandlingImport}
        onClick={() => reject(employmentsImport.id)}
        size={ButtonSize.MEDIUM}
      >
        Reject
      </Button>
    </div>
  );
}

const actionIconClassName = 'cursor-pointer text-xl';
function EditableAssigneeDetail({ employmentsImport }: { employmentsImport: EmploymentsImport }) {
  const { assignees } = useEmploymentsImportAssignees();
  const [newAssignee, setNewAssignee] = useState<ComboboxOption | null>(
    employmentsImport.assignee ? employmentsImportAssigneeToComboboxOption(employmentsImport.assignee) : null
  );
  const { mutateAsync: assignEmploymentsImport, isLoading: isAssigningUser } = useAssignEmploymentsImport();
  const [isModifyingAssignee, setIsModifyingAssignee] = useState(false);

  return (
    <div className="flex">
      <Detail
        title="Assignee"
        value={
          <div className="flex gap-2 items-center">
            {isModifyingAssignee ? (
              <>
                <Combobox
                  id="assigneeSelection"
                  size={ComboboxSize.SMALL}
                  value={newAssignee}
                  onChange={(option) => setNewAssignee(option)}
                  options={(assignees || []).map(employmentsImportAssigneeToComboboxOption)}
                  className="mb-0"
                />
                <Check
                  data-testid="confirmAssigneeChange"
                  className={classNames(actionIconClassName, {
                    'text-slate-400': isAssigningUser,
                    'cursor-default': isAssigningUser,
                  })}
                  onClick={async () => {
                    if (isAssigningUser) {
                      return;
                    }
                    await assignEmploymentsImport({
                      assignedUserId: newAssignee ? (newAssignee!.id as string) : undefined,
                      employmentsImportId: employmentsImport.id,
                    });
                    setIsModifyingAssignee(false);
                  }}
                />
                <Close
                  data-testid="cancelAssigneeChange"
                  className={classNames(actionIconClassName, {
                    'text-slate-400': isAssigningUser,
                    'cursor-default': isAssigningUser,
                  })}
                  onClick={() => setIsModifyingAssignee(false)}
                />
              </>
            ) : (
              <>
                <p>{employmentsImport?.assignee?.name || '-'}</p>
                <Edit
                  data-testid="changeAssignee"
                  onClick={() => setIsModifyingAssignee(true)}
                  className={actionIconClassName}
                />
              </>
            )}
          </div>
        }
      />
    </div>
  );
}
