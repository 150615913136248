import React, { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { Close as CloseIcon } from '../../design/icons/Icons';
import { Logo } from '../../design/logo/Logo';

export const MobileSidebar: React.FunctionComponent<{
  sidebarOpen: boolean;
  onClose: () => unknown;
  navChildren?: React.ReactNode;
  footerChildren?: React.ReactNode;
}> = ({ sidebarOpen, onClose, navChildren, footerChildren }) => {
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (sidebarOpen) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  // TODO: replace transitions with framer motion based ones
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40 md:hidden" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <CloseIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                <div className="flex flex-shrink-0 items-center px-4 sm:px-6">
                  <Logo className="h-8 w-auto" />
                </div>
                <nav className="mt-5 space-y-1 px-2">{navChildren}</nav>
              </div>
              {footerChildren && (
                <div className="flex flex-shrink-0 border-t border-gray-200 p-4">{footerChildren}</div>
              )}
            </Dialog.Panel>
          </Transition.Child>
          <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
