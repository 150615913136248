import { useParams } from 'react-router-dom';
import { useEmploymentsImport } from '../../api/hooks';
import { AppLayoutTransition } from '../../app/layout/AppLayoutTransition';
import { EmploymentsImportSection } from './EmploymentsImportSection';
import { ImportRecordsSection } from './ImportRecordsSection';

export default function EmploymentsImportPage() {
  const { employmentsImportId } = useParams();
  const { employmentsImport, isLoading } = useEmploymentsImport(employmentsImportId);

  return (
    <AppLayoutTransition id="employments-import-page">
      <div className="flex flex-col">
        <EmploymentsImportSection employmentsImport={employmentsImport} isLoading={isLoading} />
        {employmentsImportId && (
          <ImportRecordsSection
            employmentsImportId={employmentsImportId}
            assignedUserId={employmentsImport?.assignee?.userId}
          />
        )}
      </div>
    </AppLayoutTransition>
  );
}
