import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import Toast from './Toast';
import { v4 as uuid } from 'uuid';
import { CheckCircle, WarnOutline } from '../icons/Icons';
import classNames from 'classnames';

const ToastContext = createContext<{ toast?: ToastProps; setToast: (toast: ToastProps) => void }>({
  toast: undefined,
  setToast() {}, // noop
});

interface ToastProps {
  id: string;
  type?: ToastType;
  content: React.ReactNode;
}

export enum ToastType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export const ToastProvider: React.FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const [toast, setToast] = useState<ToastProps>();
  useEffect(() => {
    if (toast) {
      const timeout = setTimeout(() => {
        setToast(undefined);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [toast]);
  return (
    <ToastContext.Provider
      value={{
        toast,
        setToast,
      }}
    >
      {children}
      <Toast id={toast?.id} onClose={() => setToast(undefined)} isVisible={!!toast}>
        <div className="flex gap-4 items-center">
          {getToastIcon(toast?.type)}
          {toast?.content}
        </div>
      </Toast>
    </ToastContext.Provider>
  );
};

function getToastIcon(toastType?: ToastType) {
  const genericClass = 'w-8 h-8';
  switch (toastType) {
    case ToastType.SUCCESS:
      return <CheckCircle className={classNames(genericClass, 'text-brandGreen-mid')} />;
    case ToastType.WARNING:
      return <WarnOutline className={classNames(genericClass, 'text-brandYellow-mid')} />;
    default:
      return <></>;
  }
}

export const useToast = () => {
  const setToastProps = useContext(ToastContext).setToast;
  const setToast = useCallback(
    (content: React.ReactNode, type?: ToastType) => {
      setToastProps({ id: uuid(), type, content });
    },
    [setToastProps]
  );
  return setToast;
};
