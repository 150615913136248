import classNames from 'classnames';
import React from 'react';

export const Container: React.FC<{ children: React.ReactNode; className?: string }> = ({ children, className }) => {
  return (
    <div className={classNames('flex px-4 sm:px-6 md:px-8 justify-center', className)}>
      <div className="w-full max-w-7xl">{children}</div>
    </div>
  );
};
