import classNames from 'classnames';

export enum DetailSize {
  SMALL = 'SM',
  MEDIUM = 'MD',
  LARGE = 'LG',
}

const sizeClasses = {
  [DetailSize.SMALL]: 'text-sm',
  [DetailSize.MEDIUM]: 'text-base',
  [DetailSize.LARGE]: 'text-base',
};

export const Detail: React.FC<DetailProps> = ({ title, value, size = DetailSize.MEDIUM, className }) => {
  return (
    <div className={className}>
      <h4 className="font-medium text-sm">{title}</h4>
      <div className={classNames(sizeClasses[size])}>{value || '-'}</div>
    </div>
  );
};

export interface DetailProps {
  title: React.ReactNode;
  value: React.ReactNode;
  className?: string;
  size?: DetailSize;
}
