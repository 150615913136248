import { Location, Money, PositionType, Seniority } from '../../api/types';

const numberFormatter = new Intl.NumberFormat();
const dateFormatter = new Intl.DateTimeFormat('et-EE', { year: 'numeric', month: '2-digit', day: '2-digit' });

export function formatDate(date: Date): string {
  return dateFormatter.format(date);
}

export function formatSeniority(seniority: Seniority): string {
  return `${toTitleCase(seniority.level.replaceAll('_', ' '))} (${getSeniorityAbbreviation(seniority)})`;
}

export function formatLocation(location: Location): string {
  return (location.parentLocation ? `${location.parentLocation.name} - ` : '') + `${location.name}`;
}

export function formatAmount(amount: number): string {
  return numberFormatter.format(amount);
}

export function formatEnum(status: string): string {
  return `${toTitleCase(status.replaceAll('_', ' '))}`;
}

export function formatMoney({ value, currency }: Money): string {
  return `${numberFormatter.format(value)} ${currency}`;
}

export function toTitleCase(string: string): string {
  return string.replace(/\w\S*/g, (value) => value.charAt(0).toUpperCase() + value.substr(1).toLowerCase());
}

export function getSeniorityAbbreviation(seniority: Seniority) {
  let type = seniority.positionType === PositionType.IC ? 'IC' : 'M';
  return type + seniority.rank;
}
