import { Link } from 'react-router-dom';
import { Container } from '../../design/container/Container';

export const Footer: React.FC = () => {
  return (
    <Container className="bg-black text-white py-8">
      <div className="flex flex-col md:flex-row gap-6">
        <Link to="/terms-and-conditions" className="underline">
          Terms of Use
        </Link>
        <Link to="/privacy-policy" className="underline">
          Privacy Policy
        </Link>
        <Link to="/cookies" className="underline">
          Cookie Policy
        </Link>
        <p className="grow text-left md:text-right">
          © {Math.max(new Date().getFullYear(), 2023)} OÜ Compensation Technology
        </p>
      </div>
    </Container>
  );
};
