import { useEffect, useState } from 'react';

export function useDebouncedState<T>(value: T, delay: number = 300): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function groupBy<T, R>(
  items: T[],
  extractKey: (obj: T) => string,
  transformItem: (obj: T) => R
): Map<string, R> {
  return items.reduce(function (map, item) {
    map.set(extractKey(item), transformItem(item));
    return map;
  }, new Map<string, R>());
}

export function numberRange(start: number, end: number) {
  return new Array(end - start).fill(1).map((_, i) => i + start);
}
