import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import { REDIRECT_URL_PARAMETER, useOnboardingStep } from '../api/hooks';
import { Loader, LoaderSize } from '../design/loader/Loader';
import { OnboardingStep } from '../api/types';

export const RequiresOnboardingCompleted: React.FunctionComponent<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { currentStep } = useOnboardingStep();
  const location = useLocation();

  if (currentStep === OnboardingStep.COMPLETED) {
    return <>{children}</>;
  }

  if (currentStep) {
    return <Navigate to={`/onboarding?${REDIRECT_URL_PARAMETER}=${encodeURIComponent(location.pathname)}`} replace />;
  }

  return (
    <div className="h-full w-full flex flex-wrap content-center justify-center">
      <Loader size={LoaderSize.LARGE} />
    </div>
  );
};
