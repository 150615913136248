import {
  HeadcountRange,
  Position,
  Seniority,
  Location,
  Industry,
  EmploymentsImportAssignee,
  Currency,
  Company,
} from '../../api/types';
import { ComboboxOption } from './Combobox';
import { formatLocation, formatSeniority, formatEnum, toTitleCase } from '../../app/util/formatUtils';
import { object, string } from 'yup';

export function locationToComboboxOption(location: Location, includeParent: boolean = true): ComboboxOption {
  return { id: location.id, value: includeParent ? formatLocation(location) : location.name };
}

export function positionToComboboxOption(position: Position): ComboboxOption {
  return {
    id: position.id,
    value: `${toTitleCase(position.family.replaceAll('_', ' '))} – ${position.name}`,
  };
}

export function seniorityToComboboxOption(seniority: Seniority): ComboboxOption {
  return {
    id: seniority.id,
    value: formatSeniority(seniority),
  };
}

export function headcountRangeToComboboxOption(range: HeadcountRange): ComboboxOption {
  return {
    id: range.id,
    value: `${range.min}${range.max ? `-${range.max}` : '+'}`,
  };
}

export function industryToComboboxOption(industry: Industry): ComboboxOption {
  return {
    id: industry.id,
    value: `${toTitleCase(industry.name)}`,
  };
}

export function fundingStageToComboboxOption(fundingStage: string): ComboboxOption {
  return {
    id: fundingStage,
    value: formatEnum(fundingStage),
  };
}

export function roleNameToComboboxOption(roleName: string): ComboboxOption {
  return {
    id: roleName,
    value: formatEnum(roleName),
  };
}

export function employmentsImportTypeToComboboxOption(importType: string): ComboboxOption {
  return {
    id: importType,
    value: `${importType.replaceAll('_', ' ')}`,
  };
}

export function currencyToComboboxOption(currency: Currency): ComboboxOption {
  return {
    id: currency,
    value: currency,
  };
}

export function genericStatusToComboboxOption(status: string): ComboboxOption {
  return {
    id: status,
    value: formatEnum(status),
  };
}

export function companyToComboboxOption({ id, name }: Company): ComboboxOption {
  return {
    id: id,
    value: name,
  };
}

export function employmentsImportAssigneeToComboboxOption(
  employmentsImportAssignee: EmploymentsImportAssignee
): ComboboxOption {
  return {
    id: employmentsImportAssignee.userId,
    value: employmentsImportAssignee.name,
  };
}

export const comboboxOptionShape = object().shape({
  id: string().required(),
  value: string().required(),
});
