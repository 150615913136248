import classNames from 'classnames';
import { numberRange } from '../../app/common/util';
import { ChevronLeft, ChevronRight } from '../icons/Icons';

const THREE_DOTS = '...';

export function Pagination({
  totalCount,
  pageIndex,
  pageSize,
  pageCount,
  nextPage,
  gotoPage,
  previousPage,
  canNextPage,
  canPreviousPage,
}: {
  totalCount: number;
  pageIndex: number;
  pageSize: number;
  pageCount: number;
  nextPage: () => void;
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
  previousPage: () => void;
  canNextPage: boolean;
  canPreviousPage: boolean;
}) {
  const startIndex = totalCount === 0 ? 0 : pageSize * pageIndex + 1;
  const endIndex = Math.min(totalCount, pageSize * (pageIndex + 1));

  const pageIndexes = getPageIndexArray({ pageCount, pageIndex });
  return (
    <nav className="flex items-center justify-between pt-4" aria-label="Table navigation">
      <span className="text-sm font-normal text-slate-800">
        Showing <span className="font-semibold">{startIndex}</span> to <span className="font-semibold">{endIndex}</span>{' '}
        of <span className="font-semibold">{totalCount}</span> results
      </span>
      <ul className="inline-flex items-center -space-x-px">
        <li>
          <button
            className="block px-3 py-2 ml-0 leading-tight text-slate-900 bg-white border border-slate-300 rounded-l-lg enabled:hover:bg-slate-100 disabled:text-slate-400"
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            <span className="sr-only">Previous</span>
            <ChevronLeft className="w-5 h-5" />
          </button>
        </li>

        {pageIndexes.map((i) => {
          const isChunkSeparator = i === THREE_DOTS;
          return (
            <li key={i}>
              <button
                disabled={isChunkSeparator}
                className={classNames('px-3 py-2 leading-tight text-slate-900 bg-white border border-slate-300', {
                  'text-brandGreen-dark bg-brandGreen-light font-medium': i === pageIndex,
                  'hover:bg-slate-100': i !== pageIndex && !isChunkSeparator,
                })}
                onClick={() => gotoPage(+i)}
              >
                {isChunkSeparator ? i : (i as number) + 1}
              </button>
            </li>
          );
        })}
        <li>
          <button
            className="block px-3 py-2 leading-tight text-slate-900 bg-white border border-slate-300 rounded-r-lg enabled:hover:bg-slate-100 disabled:text-slate-400"
            onClick={nextPage}
            disabled={!canNextPage}
          >
            <span className="sr-only">Next</span>
            <ChevronRight className="w-5 h-5" />
          </button>
        </li>
      </ul>
    </nav>
  );
}

const PAGE_CHUNK_SIZE = 3;
const MAX_PAGE_INDEXES = 2 * PAGE_CHUNK_SIZE;

function getPageIndexArray({ pageCount, pageIndex }: { pageCount: number; pageIndex: number }): (string | number)[] {
  if (pageCount <= 0) {
    return [0];
  }
  if (pageCount <= MAX_PAGE_INDEXES) {
    return numberRange(0, pageCount);
  }

  if (pageIndex > pageCount - MAX_PAGE_INDEXES) {
    return [THREE_DOTS, ...numberRange(pageCount - MAX_PAGE_INDEXES, pageCount)];
  }

  const chunkPadding = 1;
  const activeChunkStart = Math.max(0, pageIndex - chunkPadding);
  const activePageChunk = numberRange(activeChunkStart, activeChunkStart + PAGE_CHUNK_SIZE);
  const lastPageChunk = numberRange(pageCount - PAGE_CHUNK_SIZE, pageCount);
  return [...activePageChunk, THREE_DOTS, ...lastPageChunk];
}
