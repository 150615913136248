import React from 'react';
import classNames from 'classnames';

export enum InputSize {
  SMALL = 'SM',
  MEDIUM = 'MD',
  LARGE = 'LG',
}

export interface InputProps {
  id?: string;
  name?: string;
  type?: 'email' | 'password' | 'text' | 'number' | 'date';
  size?: InputSize;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  min?: number | string;
  max?: number | string;
  title?: string;
  readOnly?: boolean;
  value: string;
  disabled?: boolean;

  block?: boolean;
  className?: string;
  autoFocus?: boolean;
}

const sizeClasses = {
  [InputSize.SMALL]: 'px-3 py-2 text-sm',
  [InputSize.MEDIUM]: 'px-4 py-3 text-base',
  [InputSize.LARGE]: 'px-5 py-4 text-lg font-medium',
};

export function getInputClassNames({
  size = InputSize.MEDIUM,
  block = false,
  isFocused,
  isDisabled,
}: { size?: InputSize; block?: boolean; isFocused?: boolean; isDisabled?: boolean } = {}) {
  return classNames(
    'transition ease-in-out duration-200 block rounded-md disabled:bg-slate-200 focus:ring-slate-500 shadow-sm',
    'border border-solid border-slate-300 focus:border-slate-500',
    sizeClasses[size],
    {
      'w-full': block,
      'border-slate-500 ring-slate-500': isFocused,
      'bg-slate-200': isDisabled,
      'hover:bg-slate-100': !isDisabled,
    }
  );
}

export const Input: React.FunctionComponent<InputProps> = ({
  id,
  name,
  type = 'text',
  size = InputSize.MEDIUM,
  onChange,
  onBlur,
  onClick,
  title,
  value,
  block,
  className: customClassName,
  disabled,
  ...rest
}) => {
  const className = classNames(getInputClassNames({ size, block, isDisabled: disabled }), customClassName);

  return (
    <input
      id={id}
      type={type}
      title={title}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      onClick={onClick}
      {...rest}
      className={className}
      value={value}
      disabled={disabled}
    />
  );
};
