import React from 'react';
import { Container } from '../design/container/Container';
import { Header, HeaderSize } from '../design/header/Header';

interface AuthenticationLayoutProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
}

export const AuthenticationLayout: React.FunctionComponent<AuthenticationLayoutProps> = ({ title, children }) => {
  return (
    <Container>
      <div className="mx-auto max-w-7xl sm:py-10 lg:py-16">
        <div className="mx-auto max-w-xl">
          {title ? (
            <Header size={HeaderSize.LARGE} className="mb-6 mt-8 text-center">
              {title}
            </Header>
          ) : (
            ''
          )}
          {children}
        </div>
      </div>
    </Container>
  );
};
