import classNames from 'classnames';

export enum HeaderSize {
  SMALL = 'SM',
  MEDIUM = 'MD',
  LARGE = 'LG',
}

const sizeClasses = {
  [HeaderSize.SMALL]: 'text-xl font-medium text-gray-900 mb-4 tracking-tight',
  [HeaderSize.MEDIUM]: 'text-2xl font-semibold text-gray-900 mb-6 tracking-tight',
  [HeaderSize.LARGE]: 'text-3xl font-semibold text-gray-900 mb-8 tracking-tight',
};

export function Header({
  size = HeaderSize.MEDIUM,
  className,
  children,
}: {
  size?: HeaderSize;
  className?: string;
  children: React.ReactNode;
}) {
  const style = classNames(sizeClasses[size], className);
  switch (size) {
    case HeaderSize.SMALL:
      return <h3 className={style}>{children}</h3>;
    case HeaderSize.MEDIUM:
      return <h2 className={style}>{children}</h2>;
    case HeaderSize.LARGE:
      return <h1 className={style}>{children}</h1>;
  }
}
