import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column, ColumnInstance, Filters, Row, useExpanded, useFilters, usePagination, useTable } from 'react-table';
import { useImportRecords, useSelf } from '../../api/hooks';
import { FilterImportRecordsQuery, ImportRecord, ImportRecordWithLabels, ImportRecordStatus } from '../../api/types';
import { Button, ButtonSize } from '../../design/button/Button';
import { formatLocation, formatMoney, formatSeniority, formatEnum } from '../../app/util/formatUtils';
import { ComboboxOption } from '../../design/combobox/Combobox';
import { genericStatusToComboboxOption } from '../../design/combobox/optionTransformers';
import { Detail, DetailSize } from '../../design/detail/Detail';
import { Table } from '../../design/table/Table';
import { OptionFilter } from '../../design/table/TableFilters';
import { DetailsLayout } from '../../app/layout/DetailsLayout';
import { Header, HeaderSize } from '../../design/header/Header';
import { getLocationString } from '../importRecords/importRecordUtils';

const PAGE_SIZE = 20;
export function ImportRecordsSection({
  employmentsImportId,
  assignedUserId,
}: {
  employmentsImportId: string;
  assignedUserId?: string;
}) {
  const columns = useColumns(assignedUserId);
  const [filter, setFilter] = useState<FilterImportRecordsQuery>({
    employmentsImportId,
    offset: 0,
    limit: PAGE_SIZE,
  });
  const { importRecords, totalCount, isLoading } = useImportRecords(filter);
  const pageCount = useMemo(() => (totalCount ? Math.ceil(totalCount / PAGE_SIZE) : -1), [totalCount]);

  const data = useMemo(
    () => importRecords?.map((ir) => ({ ...ir, expanded: ir.status === ImportRecordStatus.CONFIRMED })) || [],
    [importRecords]
  );

  const table = useTable(
    {
      columns,
      data,
      manualFilters: true,
      manualPagination: true,
      pageCount,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE,
      },
    },
    useFilters,
    useExpanded,
    usePagination
  );
  const {
    state: { filters, pageIndex, pageSize },
  } = table;

  useEffect(() => {
    setFilter(tableFiltersToQuery({ employmentsImportId, filters, pageIndex, pageSize }));
  }, [filters, pageIndex, pageSize, employmentsImportId]);

  return (
    <div className="mt-8">
      <Header size={HeaderSize.LARGE}>Import Records</Header>
      <Table
        table={table}
        totalCount={totalCount || 0}
        isLoading={isLoading}
        noDataMessage="No import records"
        renderRowSubComponent={ConfirmedRecordDetails}
      />
    </div>
  );
}

function ConfirmedRecordDetails({ row }: { row: Row<ImportRecordWithLabels> }) {
  const { labels, terminationTime } = row.original;
  if (!!terminationTime) {
    return <h3 className="text-center text-xl font-semibold text-gray-500">TERMINATED</h3>;
  }
  if (!labels) {
    // should never reach here
    return <h3 className="text-center text-xl font-semibold text-gray-500">NO LABELS</h3>;
  }
  const { position, seniority, yearlySalary, location } = labels;
  return (
    <DetailsLayout>
      <Detail size={DetailSize.SMALL} title="Position" value={position.name || ''} />
      <Detail size={DetailSize.SMALL} title="Yearly salary" value={formatMoney(yearlySalary)} />
      <Detail size={DetailSize.SMALL} title="Location" value={formatLocation(location)} />
      <Detail size={DetailSize.SMALL} title="Seniority" value={formatSeniority(seniority)} />
    </DetailsLayout>
  );
}

function useColumns(assignedUserId?: string) {
  const navigate = useNavigate();
  const self = useSelf();
  // Column id has to match the keys of FilterEmploymentsImportsQuery to be accounted in the filter
  const columns = useMemo<Column<ImportRecord>[]>(
    () => [
      {
        id: 'jobTitle',
        Header: 'Job Title',
        accessor: 'jobTitle',
        disableFilters: true,
      },
      {
        id: 'statuses',
        Header: 'Status',
        accessor: ({ status }) => formatEnum(status),
        Filter: ({ column }: { column: ColumnInstance<ImportRecord> }) => {
          const options = useMemo(() => Object.keys(ImportRecordStatus).map(genericStatusToComboboxOption), []);
          return <OptionFilter column={column} options={options} isMulti={true} />;
        },
      },
      {
        id: 'employmentStatus',
        Header: 'Employment Status',
        accessor: 'employmentStatus',
        disableFilters: true,
      },
      {
        id: 'salary',
        Header: 'Salary',
        accessor: (record) => record.salary && formatMoney(record.salary),
        disableFilters: true,
      },
      {
        id: 'payPeriod',
        Header: 'Pay Period',
        accessor: 'payPeriod',
        disableFilters: true,
      },
      {
        id: 'location',
        Header: 'Location',
        accessor: (row: ImportRecord) => getLocationString(row),
        disableFilters: true,
      },
      {
        id: 'labeling',
        Header: 'Label',
        accessor: (row: ImportRecord) => row.id,
        disableFilters: true,
        Cell: ({ value, row: { original: importRecord } }: { value: string; row: Row<ImportRecord> }) => {
          return (
            <Button
              disabled={assignedUserId !== self?.id || importRecord.status === ImportRecordStatus.ARCHIVED}
              size={ButtonSize.SMALL}
              onClick={async () => {
                navigate(`records/labeling/${value}`);
              }}
            >
              Label
            </Button>
          );
        },
      },
    ],
    [navigate, assignedUserId, self]
  );
  return columns;
}

function tableFiltersToQuery({
  employmentsImportId,
  filters,
  pageIndex,
  pageSize,
}: {
  employmentsImportId: string;
  filters: Filters<ImportRecord>;
  pageIndex: number;
  pageSize: number;
}): FilterImportRecordsQuery {
  const offset = pageIndex * pageSize;
  const statusFilters = filters.find((filter) => filter.id === 'statuses')?.value || [];
  const statuses = statusFilters.map((f: ComboboxOption) => f.id as ImportRecordStatus);
  return { employmentsImportId, offset, limit: pageSize, statuses };
}
