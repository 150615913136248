import React, { useMemo } from 'react';
import { SeniorityBenchmark } from '../../../api/types';
import { getSeniorityAbbreviation } from '../../util/formatUtils';
import { PercentilesTable } from './PercentilesTable';
import { RelevanceElement } from './RelevanceElement';

export const SeniorityBenchmarks: React.FunctionComponent<{
  benchmarks: SeniorityBenchmark[];
  selectedSeniorityId?: string;
  targetPercentile: { percentile: number };
}> = ({ benchmarks, selectedSeniorityId, targetPercentile }) => {
  const orderedBenchmarks = useMemo(() => getOrderedBenchmarks(benchmarks), [benchmarks]);

  return (
    <div className="grid grid-cols-[min-content_1fr] grid-rows-[min-content] gap-4 [&>*:nth-child(4n+5)]:mt-6 [&>*:nth-child(4n+6)]:mt-6">
      {orderedBenchmarks.map((benchmark, i) => (
        <React.Fragment key={`${benchmark.seniority.id}-${i}`}>
          <div>
            <div className="flex p-2 bg-brandYellow-mid bg-opacity-20 text-brandYellow-mid rounded-md justify-center">
              {getSeniorityAbbreviation(benchmark.seniority)}
            </div>
          </div>

          <div>
            {benchmark.seniority.description && (
              <div className="text-sm p-4 rounded-md bg-brandGreen-light">{benchmark.seniority.description}</div>
            )}
          </div>

          <div className="flex items-center">
            <RelevanceElement
              id={`${benchmark.seniority.id}-benchmarkTable-${i}`}
              relevanceIndicator={benchmark.companySampleSizeRelevance}
            />
          </div>

          <div className="flex items-center w-full overflow-auto">
            <PercentilesTable
              percentiles={benchmark.percentiles}
              isSelected={selectedSeniorityId === benchmark.seniority.id}
              targetPercentile={targetPercentile}
            />
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

function getOrderedBenchmarks(benchmarks: SeniorityBenchmark[]) {
  const benchmarksCopy = [...benchmarks];
  benchmarksCopy.sort((b1, b2) => b1.seniority.rank - b2.seniority.rank);
  return benchmarksCopy;
}
