import { useState } from 'react';
import { ColumnInstance, useAsyncDebounce } from 'react-table';
import { ComboboxOption } from '../combobox/Combobox';
import DateRangeField, { DateRangeValue } from '../dateRangeField/DateRangeField';
import { Field, FieldSize, FieldType } from '../field/Field';

export function TextFilter<T extends object>({
  column: { filterValue, setFilter, id },
}: {
  column: ColumnInstance<T>;
}) {
  const [value, setValue] = useState<string | undefined>(filterValue);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value);
  }, 300);

  return (
    <Field
      id={id}
      className="min-w-[100px]"
      fieldType={FieldType.INPUT}
      size={FieldSize.SMALL}
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value || undefined);
        onChange(e.target.value);
      }}
    />
  );
}

export function OptionFilter<T extends object>({
  column: { filterValue, setFilter, id },
  options,
  isMulti = false,
}: {
  column: ColumnInstance<T>;
  options: ComboboxOption[];
  isMulti?: boolean;
}) {
  return (
    <Field
      id={id}
      className="bg-white min-w-[100px] max-w-[250px]"
      fieldType={FieldType.COMBOBOX}
      options={options}
      size={FieldSize.SMALL}
      value={filterValue || null}
      isMulti={isMulti}
      onChange={(o) => {
        setFilter(o || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
}

export function DateRangeFilters<T extends object>({
  column: { id },
  value,
  setStartDate,
  setEndDate,
}: {
  column: ColumnInstance<T>;
  value: DateRangeValue;
  setStartDate: (startDate?: string) => void;
  setEndDate: (endDate?: string) => void;
}) {
  return (
    <DateRangeField
      id={id}
      className="min-w-[120px]"
      size={FieldSize.SMALL}
      value={value}
      onChange={({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
      }}
    />
  );
}
