import { Form } from 'formik';
import { usePositionSeniorities, usePositions } from '../../../api/hooks';
import { Currency, LocationType } from '../../../api/types';
import { LocationsFormikField } from '../../../app/common/location/LocationsCombobox';
import { ComboboxOption } from '../../../design/combobox/Combobox';
import {
  currencyToComboboxOption,
  positionToComboboxOption,
  seniorityToComboboxOption,
} from '../../../design/combobox/optionTransformers';
import { FieldType, FormikField } from '../../../design/field/Field';

export function LabelsFormFields({
  values,
  setFieldValue,
  children,
}: {
  values: Labels;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  children?: React.ReactNode;
}) {
  const { position } = values;

  const { positions } = usePositions();
  const { seniorities, isLoading: isLoadingSeniorities } = usePositionSeniorities(position?.id as string, {
    baseSenioritiesOnly: true,
  });

  return (
    <>
      <Form>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4">
          <FormikField
            id="position"
            name="position"
            label="Position"
            fieldType={FieldType.COMBOBOX}
            options={(positions || []).map(positionToComboboxOption)}
            validate={(newValue: ComboboxOption | null) => {
              if (newValue?.id !== position?.id) {
                // workaround for resetting seniority when position changes
                setFieldValue('seniority', null, false);
              }
              return undefined;
            }}
          />
          <FormikField
            id="seniority"
            name="seniority"
            label="Seniority level"
            fieldType={FieldType.COMBOBOX}
            disabled={!position || isLoadingSeniorities}
            options={(seniorities || []).map(seniorityToComboboxOption)}
          />
          <LocationsFormikField
            id="location"
            name="location"
            label="Work location"
            locationType={LocationType.CITY}
            groupByCountry={true}
            isGroupHeaderSelectable={true}
          />

          <div className="flex gap-2 w-full">
            <FormikField
              id="yearlySalary"
              name="yearlySalary"
              className="flex-1"
              label="Annual salary"
              fieldType={FieldType.INPUT}
              type="number"
            />
            <FormikField
              id="salaryCurrency"
              name="salaryCurrency"
              className="w-28"
              label="Currency"
              fieldType={FieldType.COMBOBOX}
              options={Object.values(Currency).map(currencyToComboboxOption)}
              isClearable={false}
            />
          </div>
        </div>
        {children}
      </Form>
    </>
  );
}

export interface Labels {
  position: ComboboxOption | null;
  seniority: ComboboxOption | null;
  location: ComboboxOption | null;
  salaryCurrency: ComboboxOption | null;
  yearlySalary: number;
}
