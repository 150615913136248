import { SVGProps } from 'react';
import logo from './logo.png';

export const Logo: React.FunctionComponent<{ className?: string }> = ({ className }) => (
  <img src={logo} alt="Compensation.app logo" className={className} />
);

export const Hamburger: React.FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179.55 106.44" {...props}>
    <g>
      <g>
        <rect x="0" y=".31" width="126.04" height="15" rx="1" ry="1" />
        <rect x="0" y="36.03" width="126.04" height="15" rx="1" ry="1" />
        <rect x="0" y="71.75" width="126.04" height="15" rx="1" ry="1" />
      </g>
    </g>
  </svg>
);
