import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Ref, forwardRef } from 'react';

export const Card: React.FC<CardProps> = forwardRef(({ children, className }, ref) => {
  return (
    <div className={classNames('p-6 rounded-lg border border-slate-300', className)} ref={ref}>
      {children}
    </div>
  );
});

export const MotionCard = motion<CardProps>(Card);

export interface CardProps {
  children?: React.ReactNode;
  className?: string;
  ref?: Ref<HTMLDivElement>;
}
