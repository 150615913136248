import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { object, string } from 'yup';
import { usePasswordReset } from '../../api/hooks';
import { ResetPasswordCommand } from '../../api/types';
import { Button } from '../../design/button/Button';
import { FormikField } from '../../design/field/Field';
import { AuthenticationLayout } from '../AuthenticationLayout';

export const PasswordResetPage = () => {
  const { resetPassword, isSuccess, isError } = usePasswordReset();
  const location = useLocation();
  const [token, setToken] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = location.hash.replace('#', '');
    if (!token || !token.length) {
      navigate('/login');
    } else {
      setToken(token);
    }
  }, [location, navigate]);

  let content;
  if (isSuccess) {
    content = <PasswordResetSuccess />;
  } else if (isError) {
    content = <PasswordResetFailed />;
  } else {
    content = <PasswordResetForm token={token} onPasswordReset={resetPassword} />;
  }

  return <AuthenticationLayout title="Password reset">{content}</AuthenticationLayout>;
};

const PasswordResetForm = ({
  token,
  onPasswordReset,
}: {
  token: string;
  onPasswordReset: (resetCommand: ResetPasswordCommand) => Promise<void>;
}) => {
  return (
    <>
      <Formik
        initialValues={{ newPassword: '' }}
        validationSchema={object().shape({
          newPassword: string().min(8, 'Password has to be at least 8 characters').required('Enter your password'),
        })}
        onSubmit={async ({ newPassword }) => {
          await onPasswordReset({ newPassword, token });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormikField label="New password" name="newPassword" type="password" />

            <Button type="submit" primary block disabled={isSubmitting} className="mt-4">
              Reset password
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

const PasswordResetSuccess = () => {
  return (
    <div>
      <p>Password reset was successful. Log in with your new password to continue.</p>
      <Button primary block className="mt-4" type="link" href="/login">
        Login
      </Button>
    </div>
  );
};

const PasswordResetFailed = () => {
  return (
    <div>
      <p>Password reset failed. Request a new password reset link to retry.</p>
      <Button primary block className="mt-4" type="link" href="/request-password-reset">
        Request link
      </Button>
    </div>
  );
};
