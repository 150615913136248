// auth and user
export enum AuthenticationState {
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNKNOWN = 'UNKNOWN',
}

export enum LocationType {
  CITY = 'CITY',
  COUNTRY = 'COUNTRY',
}

export enum PositionType {
  IC = 'IC',
  MANAGER = 'MANAGER',
}

export enum BenchmarkRelevance {
  NONE = 'NONE',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export interface RegisterCommand {
  name: string;
  email: string;
  password: string;
}

export interface RequestPasswordResetCommand {
  email: string;
}

export interface ResetPasswordCommand {
  token: string;
  newPassword: string;
}

export interface SaveCompanyCommand {
  hqLocationId: string;
  headcountRangeId: string;
  fundingStage: string;
  industryId?: string;
  name: string;
}

export interface EditCompanyCommand {
  id: string;
  headquartersLocationId?: string;
  headcountRangeId?: string;
  fundingStage?: string;
  industryId?: string | null;
  name?: string;
  targetPercentile?: number;
}

export interface SuggestEmploymentChangesCommand {
  employmentId: string;
  seniorityId?: string;
  positionId?: string;
  locationId?: string;
  yearlySalary?: { value: number; currency: string };
  message?: string;
}

export interface LoginCommand {
  email: string;
  password: string;
}

export interface InviteUserCommand {
  email: string;
  roleName: string;
}

export interface InvitationQuery {
  token: string;
}

export interface AcceptInvitationCommand {
  token: string;
}

export interface Invitation {
  id: string;
  role: string;
  company: Company;
  user?: User;
  email: string;
  // invitation link is only included in the dto when the invitation is created so the client can use it to manually forward the invitation
  link?: string;

  createdBy: string;
  creationTime: Date;
  modificationTime: Date;
  expirationTime: Date;
}

export interface ConfirmImportRecordCommand {
  importRecordId: string;
  importRecordLabelsId: string;
  seniorityId: string;
  positionId: string;
  locationId: string;
}

export interface User {
  id: string;
  email: string;
  name: string;
  companyIds: string[];
  creationTime: Date;
  modificationTime: Date;
}

export interface EmploymentsImportAssignee {
  userId: string;
  name: string;
}

export interface UserRole {
  id: string;
  name: string;
  userId: string;
  companyId?: string;
  creationTime: Date;
}

export interface Role {
  name: string;
  description: string;
  creationTime: Date;
}

export interface Company {
  id: string;
  headquartersLocationId: string;
  headcountRangeId: string;
  fundingStage: FundingStage;
  industryId: string | null;
  name: string;
  targetPercentile: number;
  creationTime: Date;
  modificationTime: Date;
}

export interface OnboardingState {
  currentStep: OnboardingStep;
}

export enum OnboardingStep {
  USER = 'USER',
  COMPANY = 'COMPANY',
  EMPLOYMENTS_IMPORT = 'EMPLOYMENTS_IMPORT',
  EMPLOYMENTS_IMPORT_ACCEPT = 'EMPLOYMENTS_IMPORT_ACCEPT',
  COMPLETED = 'COMPLETED',
}

export interface CompanyStats {
  employmentStatistics: { totalCount: number; salaryMismatchCount: number };
}

// filters
export interface HeadcountRange {
  id: string;
  min: number;
  max?: number;
}

export interface Seniority {
  id: string;
  level: string;
  rank: number;
  positionType: PositionType;
  description: string;
}

export enum SeniorityLevel {
  // IC
  ENTRY = 'ENTRY',
  JUNIOR = 'JUNIOR',
  MID_LEVEL = 'MID_LEVEL',
  SENIOR = 'SENIOR',
  EXPERT = 'EXPERT',

  // MANAGER
  TEAM_LEAD = 'TEAM_LEAD',
  HEAD_OF = 'HEAD_OF',
  DIRECTOR = 'DIRECTOR',
  VP = 'VP',
  C_SUITE = 'C_SUITE',

  // grouped MANAGER levels
  TEAM_MANAGER = 'TEAM_MANAGER',
  DEPARTMENT_MANAGER = 'DEPARTMENT_MANAGER',
  TOP_MANAGEMENT = 'TOP_MANAGEMENT',
}

export interface Position {
  id: string;
  name: string;
  family: string;
  function: string;
  description: string;
  hasBenchmarks: boolean;
}

export interface Industry {
  id: string;
  name: string;
}

export interface Location {
  id: string;
  name: string;
  type: LocationType;
  parentLocation?: Location;
  hasBenchmarks: boolean;
}

// Benchmarking
export interface SeniorityBenchmarksQuery {
  positionId: string;
  seniorityId: string;
  headcountRangeIds?: string[];
  countryIds?: string[];
  cityIds?: string[];
  industryIds?: string[];
  fundingStages?: string[];
}

export interface RelevanceIndicator {
  relevance: BenchmarkRelevance;
  minSampleSize: number;
  maxSampleSize?: number;
}

export interface SeniorityBenchmark {
  percentiles: Percentile[] | null;
  seniority: Seniority;
  lastUpdated: Date;
  employmentSampleSizeRelevance: RelevanceIndicator;
  companySampleSizeRelevance: RelevanceIndicator;
}

export interface Percentile {
  value: number;
  score: number;
}

export interface SaveProspectCommand {
  email: string;
  message: string;
}

export interface DefaultFilters {
  positionId?: string;
  seniorityId: string;
  headcountRangeIds: string[];
  industryId?: string;
  lowerPercentile?: number;
  targetPercentile?: number;
  upperPercentile?: number;
  cityIds: string[];
  countryIds: string[];
}

// export
export interface ExportBenchmarksQuery {
  positionIds: string[];
  countryIds: string[];
  format: 'XLSX';
}

// import record
export interface ImportRecord {
  id: string;
  companyId: string;
  importId: string;
  creationTime: Date;
  modificationTime: Date;
  terminationTime?: Date;
  status: ImportRecordStatus;
  importedId: string;

  employmentStatus?: string;
  salary?: Money;
  payPeriod?: string;
  jobTitle?: string;
  country?: string;
  city?: string;
  locationName?: string;
  startedAt?: Date;
  endedAt?: Date;
  employmentType?: string;
  seniority?: string;
  rawImportValue?: object;
}

export interface ImportRecordWithLabels extends ImportRecord {
  labels?: ImportRecordLabels;
}

export interface ImportRecordLabels {
  id: string;

  position: Position;
  seniority: Seniority;
  location: Location;
  yearlySalary: Money;
  suggestedBy?: string;
  suggestionMessage?: string;

  importRecordId: string;
  creationTime: Date;
  modificationTime: Date;
}

export enum ImportRecordStatus {
  ARCHIVED = 'ARCHIVED',
  TO_BE_REVIEWED = 'TO_BE_REVIEWED',
  REQUIRES_COMPANY_VERIFICATION = 'REQUIRES_COMPANY_VERIFICATION',
  SKIPPED = 'SKIPPED',
  CONFIRMED = 'CONFIRMED',
}

export interface FilterImportRecordsQuery {
  employmentsImportId: string;
  statuses?: ImportRecordStatus[];
  limit: number;
  offset: number;
}

export interface FilterCompanyImportRecordsQuery {
  companyId: string;
  statuses?: ImportRecordStatus[];
  limit: number;
  offset: number;
}

export interface LabelImportRecordCommand {
  importRecordId: string;
  seniorityId: string;
  positionId: string;
  locationId: string;
  yearlySalary: Money;
}

export interface RequestRecordVerificationCommand {
  importRecordId: string;
  seniorityId?: string;
  positionId?: string;
  locationId?: string;
  yearlySalary: { value: number; currency: string };
}

export interface ImportRecordToLabelQuery {
  employmentsImportId: string;
  importRecordId?: string;
}

export interface Money {
  value: number;
  currency: Currency;
}

export enum Currency {
  USD = 'USD',
  CAD = 'CAD',
  EUR = 'EUR',
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ARS = 'ARS',
  AUD = 'AUD',
  AZN = 'AZN',
  BAM = 'BAM',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EEK = 'EEK',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GNF = 'GNF',
  GTQ = 'GTQ',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KHR = 'KHR',
  KMF = 'KMF',
  KRW = 'KRW',
  KWD = 'KWD',
  KZT = 'KZT',
  LBP = 'LBP',
  LKR = 'LKR',
  LTL = 'LTL',
  LVL = 'LVL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MOP = 'MOP',
  MUR = 'MUR',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SOS = 'SOS',
  SYP = 'SYP',
  THB = 'THB',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  UYU = 'UYU',
  UZS = 'UZS',
  VEF = 'VEF',
  VND = 'VND',
  XAF = 'XAF',
  XOF = 'XOF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMK = 'ZMK',
  ZWL = 'ZWL',
}

// employments import
export interface EmploymentsImport {
  id: string;
  status: EmploymentsImportStatus;
  source: string;
  type: EmploymentsImportType;
  assignee?: EmploymentsImportAssignee;
  createdBy: string | null;
  companyId: string;
  creationTime: Date;
  modificationTime: Date;
}

export interface EmploymentsImportListItem {
  id: string;
  status: string;
  type: string;
  assignee?: EmploymentsImportAssignee;
  companyName: string;
  creationTime: Date;

  inReviewRecordCount: number;
  skippedRecordCount: number;
  totalRecordCount: number;
}

export interface FilterEmploymentsImportsQuery {
  companyName?: string;
  type?: string[];
  status?: string[];
  createdFrom?: Date;
  createdTo?: Date;
  assignedUserId?: string;
  limit: number;
  offset: number;
}

export interface EmploymentsImportStatusUpdateCommand {
  status: string;
  employmentsImportId: string;
}

export interface PositionSenioritiesQuery {
  baseSenioritiesOnly?: boolean;
}

export interface LocationsQuery {
  withBenchmarksOnly?: boolean;
  locationType: LocationType;
  name?: string;
  limit?: number;
}

export interface PatchEmploymentsImportAssigneeCommand {
  employmentsImportId: string;
  assignedUserId?: string;
}

export enum EmploymentsImportStatus {
  IN_REVIEW = 'IN_REVIEW',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum FundingStage {
  NONE = 'NONE',
  PRE_SEED = 'PRE_SEED',
  SEED = 'SEED',
  SERIES_A = 'SERIES_A',
  SERIES_B = 'SERIES_B',
  SERIES_C = 'SERIES_C',
  SERIES_D = 'SERIES_D',
  SERIES_E = 'SERIES_E',
  SERIES_F = 'SERIES_F',
  IPO = 'IPO',
  UNKNOWN = 'UNKNOWN',
}

// HRIS integration
export interface KomboConnectionLink {
  link: string;
}

export interface CreateKomboIntegrationCommand {
  token: string;
}

export interface HRISIntegration {
  id: string;
  hris: string;
  type: string;
  companyId: string;
  createdBy: string;
  creationTime: Date;
}

export enum EmploymentsImportType {
  INTEGRATION = 'INTEGRATION',
  CSV = 'CSV',
  DEMO = 'DEMO',
}

export enum RoleName {
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  ALIGN_ADMIN = 'ALIGN_ADMIN',
}

export function currencyCodeFromString(code?: string): Currency {
  const upperCase = code?.toUpperCase();
  const currency = Currency[upperCase as keyof typeof Currency];
  if (!currency) {
    throw new Error(`Invalid currency code: ${code}`);
  }
  return currency;
}

// employment
export interface FilterCompanyEmploymentsQuery {
  companyId: string;
  limit: number;
  offset: number;
}

export interface Employment {
  id: string;
  salary: number;
  salaryPercentile?: number;
  seniority: Seniority;
  position: Position;
  location: Location;
  importedId: string;
  companyId: string;
  salaryMismatchLevel?: SalaryMismatchLevel;

  originSalary: Money;

  creationTime: Date;
  modificationTime: Date;
}

export enum SalaryMismatchLevel {
  LOW_BELOW = 'LOW_BELOW',
  MEDIUM_BELOW = 'MEDIUM_BELOW',
  HIGH_BELOW = 'HIGH_BELOW',
  LOW_ABOVE = 'LOW_ABOVE',
  MEDIUM_ABOVE = 'MEDIUM_ABOVE',
  HIGH_ABOVE = 'HIGH_ABOVE',
}

export interface Subscription {
  id: string;
  companyId: string;
  type: SubscriptionType;
  createdBy: string;
  creationTime: string;
  modificationTime: string;
  isActive: boolean;
}

export enum SubscriptionType {
  PREMIUM = 'PREMIUM',
  PREMIUM_PRIVATE = 'PREMIUM_PRIVATE',
}

// common
export interface PaginationResult<T> {
  items: T[];
  totalCount: number;
}

// intercom
export interface IntercomSettings {
  userHash: string;
  userId: string;
}
