import { ReactElement } from 'react';
import { BenchmarkRelevance, RelevanceIndicator } from '../../../api/types';
import { Check, CheckCircle, WarnOutline } from '../../../design/icons/Icons';
import { Tooltip } from 'react-tooltip';

export const RelevanceElement = ({
  id,
  relevanceIndicator,
}: {
  id: string;
  relevanceIndicator: RelevanceIndicator;
}) => {
  if (relevanceIndicator.relevance === BenchmarkRelevance.NONE) {
    return <></>;
  }

  let relevanceElement: ReactElement;
  let relevanceText: string;
  switch (relevanceIndicator.relevance) {
    case BenchmarkRelevance.LOW:
      relevanceElement = <WarnOutline className="text-4xl text-brandYellow-mid" />;
      relevanceText = 'Small Sample';
      break;
    case BenchmarkRelevance.MEDIUM:
      relevanceElement = <Check className="text-4xl text-brandGreen-mid" />;
      relevanceText = 'Relevant Sample';
      break;
    case BenchmarkRelevance.HIGH:
      relevanceElement = <CheckCircle className="text-4xl text-brandGreen-mid" />;
      relevanceText = 'Highly Relevant Sample';
      break;
  }

  return (
    <div className="flex flex-col items-center max-w-min">
      <div data-tooltip-id={id}>{relevanceElement}</div>
      <Tooltip id={id} content={getTooltipText(relevanceIndicator)}></Tooltip>
      <div className="text-xs text-center">{relevanceText}</div>
    </div>
  );
};

function getTooltipText({ maxSampleSize, minSampleSize }: RelevanceIndicator) {
  if (maxSampleSize) {
    return `Includes data from ${minSampleSize} to ${maxSampleSize} companies`;
  }
  return `Includes data from more than ${minSampleSize} companies`;
}
