import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { AuthenticationState } from '../api/types';
import { useAuthenticationStatus } from '../api/hooks';

const AuthenticationContext = createContext<{
  state: AuthenticationState;
  setState: (state: AuthenticationState) => void;
}>({
  state: AuthenticationState.UNKNOWN,
  setState() {}, // noop
});

AuthenticationContext.displayName = 'AuthenticationContext';

// TODO: can we refactor this to just use the authentication status hook?
export const AuthenticationProvider: React.FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const [state, setState] = useState(AuthenticationState.UNKNOWN);
  const authenticationStatus = useAuthenticationStatus({ enabled: state === AuthenticationState.UNKNOWN });
  useEffect(() => {
    if (state === AuthenticationState.UNKNOWN && authenticationStatus !== AuthenticationState.UNKNOWN) {
      setState(authenticationStatus);
    }
  }, [authenticationStatus, state]);
  return (
    <AuthenticationContext.Provider
      value={{
        state,
        setState(state: AuthenticationState) {
          setState(state);
        },
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationState = () => useContext(AuthenticationContext).state;
export const useSetAuthenticationState = () => useContext(AuthenticationContext).setState;
