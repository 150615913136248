import { Popover, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { formatDate } from '../../app/util/formatUtils';
import { Button, ButtonSize } from '../button/Button';
import { Field, FieldProps, FieldSize, FieldType } from '../field/Field';

export default function DateRangeField({
  onChange,
  value,
  ...fieldProps
}: {
  onChange: (val: DateRangeValue) => void;
  value: DateRangeValue;
} & Omit<FieldProps, 'onChange' | 'value' | 'onClick' | 'title' | 'fieldType' | 'readOnly'>) {
  const [startDate, setStartDate] = useState(value.startDate || '');
  const [endDate, setEndDate] = useState(value.endDate || '');

  return (
    <Popover as="div" className="relative inline-block text-left w-full" title={dateRangeValueToString(value)}>
      <Popover.Button
        as={Field}
        {...fieldProps}
        fieldType={FieldType.INPUT}
        value={dateRangeValueToString(value)}
      ></Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="absolute p-4 flex flex-col gap-4 z-50 mt-1 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {({ close }) => (
            <>
              <Field
                id="startDate"
                label="From"
                size={FieldSize.SMALL}
                fieldType={FieldType.INPUT}
                type="date"
                value={startDate}
                max={endDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <Field
                id="endDate"
                label="To"
                size={FieldSize.SMALL}
                fieldType={FieldType.INPUT}
                type="date"
                value={endDate}
                min={startDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
              <div className="flex gap-4">
                <Button
                  primary
                  size={ButtonSize.SMALL}
                  onClick={() => {
                    onChange({ startDate, endDate });
                    close();
                  }}
                >
                  Apply
                </Button>
                <Button
                  size={ButtonSize.SMALL}
                  onClick={() => {
                    setStartDate(value?.startDate || '');
                    setEndDate(value?.endDate || '');
                    close();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

function dateRangeValueToString({ startDate, endDate }: DateRangeValue) {
  const prettyStartDate = startDate ? formatDate(new Date(startDate)) : '';
  const prettyEndDate = endDate ? `- ${formatDate(new Date(endDate))}` : '';
  return `${prettyStartDate} ${prettyEndDate}`;
}

export interface DateRangeValue {
  startDate?: string;
  endDate?: string;
}
