import classNames from 'classnames';
import { ComponentType } from 'react';
import readyPlayerMeLogo from './readyPlayerMeLogo.png';
import tuumLogo from './tuumLogo.png';
import lightYearLogo from './lightYearLogo.svg';
import testlioLogo from './testlioLogo.webp';
import printifyLogo from './printifyLogo.svg';

export const LightYearLogo: React.FC<React.ComponentProps<'img'>> = (props) => (
  <ImageAssetLogo src={lightYearLogo} alt="LightYear logo" className="" {...props} />
);

export const ReadyPlayerMeLogo: ComponentType<React.ComponentProps<'img'>> = (props) => (
  <ImageAssetLogo src={readyPlayerMeLogo} alt="Ready Player Me logo" className="h-10" {...props} />
);

export const TuumLogo: ComponentType<React.ComponentProps<'img'>> = (props) => (
  <ImageAssetLogo src={tuumLogo} alt="Tuum logo" className="h-6" {...props} />
);

export const TestlioLogo: ComponentType<React.ComponentProps<'img'>> = (props) => (
  <ImageAssetLogo src={testlioLogo} alt="Testlio logo" className="h-12" {...props} />
);

export const PrintifyLogo: ComponentType<React.ComponentProps<'img'>> = (props) => (
  <ImageAssetLogo src={printifyLogo} alt="Printify logo" className="h-10" {...props} />
);

const ImageAssetLogo: ComponentType<React.ComponentProps<'img'>> = (props) => (
  <img {...props} alt={props.alt} className={classNames('block w-auto mx-auto', props.className)} />
);
