import { ImportRecord } from '../../api/types';
import { Detail, DetailSize } from '../../design/detail/Detail';

export function ImportRecordLocationDetails({
  importRecord,
  size = DetailSize.MEDIUM,
}: {
  importRecord?: ImportRecord;
  size?: DetailSize;
}) {
  if (!importRecord) {
    return <></>;
  }

  return importRecord.country || importRecord.city ? (
    <>
      <Detail size={size} title="Country" value={importRecord.country} />
      <Detail size={size} title="City" value={importRecord.city} />
    </>
  ) : (
    <>
      <Detail size={size} title="Location" value={importRecord.locationName} />
    </>
  );
}
