import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Close as CloseIcon } from '../icons/Icons';
import classNames from 'classnames';

export enum ModalSize {
  SMALL = 'SM',
  MEDIUM = 'MD',
  LARGE = 'LG',
}

const sizeClasses = {
  [ModalSize.SMALL]: 'sm:w-full sm:max-w-xl',
  [ModalSize.MEDIUM]: 'sm:w-full sm:max-w-2xl',
  [ModalSize.LARGE]: 'sm:w-full sm:max-w-4xl',
};

export const Modal = ({
  open,
  onClose,
  children,
  size = ModalSize.LARGE,
  afterLeave,
}: {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: ModalSize;
  afterLeave?: () => void;
}) => {
  return (
    <Transition.Root show={open} as={Fragment} afterLeave={afterLeave}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6',
                  sizeClasses[size]
                )}
              >
                <CloseIcon className="h-6 w-6 text-black ml-auto cursor-pointer" aria-hidden="true" onClick={onClose} />
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
